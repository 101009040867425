import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames/bind';
import Link from 'next/link';
import { useRouter } from 'next/router';

import ActiveLink from '@/components/active-link/ActiveLink';
import Button from '@/components/form/button/Button';
import Card from '@/components/containers/card/Card';
import Dropdown from '@/components/form/dropdown/Dropdown';
import FavoritesMenu from '@/components/chrome/header/FavoritesMenu';
import IconButton from '@/components/icon/IconButton';
import LocationCard from './LocationCard';
import MenuButton from '@/components/chrome/header/MenuButton';
import SearchBar from '@/components/search/SearchBar';
import SearchButton from '@/components/chrome/header/SearchButton';
import SmartImage from '@/components/image/SmartImage';
import Skeleton from '@/components/loading/skeleton/Skeleton';
import UserButton from '@/components/chrome/user-menu/UserButton';
import useDevice from '@/hooks/useDevice';
import { Events, trackEvent } from '@/utils/analytics';
import { IconType } from '@/components/icon/Icon';
import { getLocation, removeLocation } from '@/common/location';
import { getIpAddress } from '@/utils/http';
import { goSignIn, goSignUp } from '@/common/auth';
import { SearchState, selectSearch, setDataSource } from '@/store/search';
import { AuthState, selectAuth } from '@/store/auth';
import { UserState, selectUser, setIpAddress } from '@/store/user';
import { SearchPlaceholders, SearchSources } from '@/utils/constants';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export default function AppHeader() {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const ipAddress = useRef<string>();
    const { isMobile, isTablet, calculating } = useDevice();

    const { authenticated } = useAppSelector<AuthState>(selectAuth);
    const { searchDataSource } = useAppSelector<SearchState>(selectSearch);
    const { city, country, region } = useAppSelector<UserState>(selectUser);

    const [favoritesMenuOpen, setFavoritesMenuOpen] = useState(false);

    const [pageClass, setPageClass] = useState('');

    const iconButtonBoneCss = 'w-9 h-9';

    const getAddress = () => {
        const location: string[] = [];

        if (city) location.push(city);
        if (region) location.push(region);
        if (country) location.push(country);

        return location.join(', ');
    };

    const getSearchPlaceholder = () => {
        let placeholder = '';

        switch (searchDataSource) {
            case SearchSources.Festivals:
                placeholder = SearchPlaceholders.Festivals;
                break;

            case SearchSources.Artists:
                placeholder = SearchPlaceholders.Artists;
                break;
            case SearchSources.Locations:
                placeholder = SearchPlaceholders.Locations;
                break;
        }

        return placeholder;
    };

    const signInHandler = () => goSignIn();
    const signUpHandler = () => {
        trackEvent(Events.ClickedSignUp, {
            path: window.location.pathname,
        });

        goSignUp();
    };

    const notificationsHandler = () => {
        router.push('/user/notifications');
    };

    const toggleFavorites = () => {
        if (!favoritesMenuOpen) {
            setFavoritesMenuOpen(true);
        } else {
            setFavoritesMenuOpen(false);
        }
    };

    const onHandleFavoritesToggleOutside = () => setFavoritesMenuOpen(false);

    const onUpdateLocation = async () => {
        trackEvent(Events.ClickedEnabledLocation, {
            page: window.location.pathname,
        });

        await getLocation(authenticated, dispatch);
    };

    const onRemoveLocation = async () => {
        await removeLocation(authenticated, dispatch);
    };

    useEffect(() => {
        const activePathname = new URL(router.asPath, location.href).pathname;

        if (activePathname === '/') {
            setPageClass('home');
            dispatch(setDataSource(SearchSources.Festivals));
        } else if (activePathname.indexOf(SearchSources.Festivals) === -1) {
            if (activePathname.indexOf(`/${SearchSources.Artists}`) === 0)
                dispatch(setDataSource(SearchSources.Artists));
            if (activePathname.indexOf(`/${SearchSources.Locations}`) === 0)
                dispatch(setDataSource(SearchSources.Locations));
        } else {
            setPageClass('internal');
            dispatch(setDataSource(SearchSources.Festivals));
        }
    }, [dispatch, router.asPath]);

    useEffect(() => {
        const handleRouteChange = () => {
            setFavoritesMenuOpen(false);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        (async () => {
            if (!ipAddress.current) {
                const ip = await getIpAddress();

                if (ip) {
                    ipAddress.current = ip;
                    dispatch(setIpAddress(ip));
                }
            }
        })();
    }, [ipAddress, dispatch]);

    return (
        <header
            id='app-header'
            className={cx('app-header', pageClass, {
                '--has-location': country,
            })}>
            <div className={cx('__nav-container', 'page__gutter')}>
                <nav
                    id='site-nav'
                    className={cx(
                        'nav-wrapper',
                        {
                            '--authenticated': authenticated,
                        },
                        {
                            '--not-authenticated': !authenticated,
                        }
                    )}>
                    <div className={cx('menu-brand-container')}>
                        <div className={cx('__mobile-menu')}>
                            <MenuButton />
                        </div>
                        <div className={cx('brand')}>
                            <Link
                                href='/'
                                className={cx('logo')}
                                aria-label='FestGPS'>
                                <SmartImage
                                    src='/images/global/festgps-h.svg'
                                    alt='FestGPS'
                                    className={cx('__desktop-img')}
                                    imgClassName={cx('__img')}
                                    width={128}
                                    height={44}
                                    quality={100}
                                    priority={!isMobile && !isTablet}
                                />
                                <SmartImage
                                    src='/images/global/festgps_head_only_white.svg'
                                    alt='FestGPS'
                                    className={cx('__mobile-img')}
                                    imgClassName={cx('__img')}
                                    width={48}
                                    height={36}
                                    quality={100}
                                    priority={isMobile || isTablet}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className={cx('search-container')}>
                        <SearchBar
                            placeholder={getSearchPlaceholder()}
                            isButton
                        />
                    </div>
                    <div className={cx('mobile-menu-items')}>
                        <div className={cx('mobile__search-container')}>
                            <SearchButton />
                        </div>
                        {authenticated ? (
                            <IconButton
                                type={IconType.Bell}
                                ariaLabel='notifications'
                                onPress={notificationsHandler}
                                size='lg'
                                theme='outline-primary'
                                transparent
                            />
                        ) : null}
                        <LocationCard
                            address={getAddress()}
                            onUpdateLocation={onUpdateLocation}
                            onRemoveLocation={onRemoveLocation}
                        />
                        {authenticated ? (
                            <UserButton authenticated={authenticated} />
                        ) : (
                            <div className={cx('auth__buttons', '--xs')}>
                                <div className={cx('auth__button')}>
                                    <Button
                                        onPress={signInHandler}
                                        label='Sign In'
                                        size={
                                            !isMobile && !isTablet
                                                ? 'default'
                                                : 'small'
                                        }
                                        scroll={false}
                                    />
                                </div>
                                <div className={cx('auth__button')}>
                                    <Button
                                        theme='secondary'
                                        onPress={signUpHandler}
                                        label='Sign Up'
                                        size={
                                            !isMobile && !isTablet
                                                ? 'default'
                                                : 'small'
                                        }
                                        scroll={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <ul className={cx('main-nav')}>
                        <li className={cx('nav__search--button')}>
                            <SearchButton />
                        </li>
                        <li>
                            <ActiveLink
                                href='/festivals'
                                className={cx('nav__item')}
                                activeClassName={cx('nav__item--active')}>
                                Festivals
                            </ActiveLink>
                        </li>
                        <li>
                            <ActiveLink
                                href='/artists'
                                className={cx('nav__item')}
                                activeClassName={cx('nav__item--active')}>
                                Artists
                            </ActiveLink>
                        </li>
                        <li>
                            <ActiveLink
                                href='/locations'
                                className={cx('nav__item')}
                                activeClassName={cx('nav__item--active')}>
                                Locations
                            </ActiveLink>
                        </li>
                        <li>
                            <ActiveLink
                                href='/genres'
                                className={cx('nav__item')}
                                activeClassName={cx('nav__item--active')}>
                                Genres
                            </ActiveLink>
                        </li>
                        <li>
                            <ActiveLink
                                href='/blog'
                                className={cx('nav__item')}
                                activeClassName={cx('nav__item--active')}>
                                Fest Digest
                            </ActiveLink>
                        </li>
                        {authenticated ? (
                            <>
                                <li>
                                    <div className={cx('nav__separator')}></div>
                                </li>
                                <li>
                                    <ActiveLink
                                        href='/user/compare'
                                        className={cx('nav__item')}
                                        activeClassName={cx(
                                            'nav__item--active'
                                        )}>
                                        Compare
                                    </ActiveLink>
                                </li>
                                <li>
                                    <Dropdown
                                        toggler={
                                            <Button
                                                label='Favorites'
                                                customClassName={cx(
                                                    'nav__item'
                                                )}
                                                paddingHorizonal={false}
                                                onPress={toggleFavorites}
                                                link
                                            />
                                        }
                                        toggleState={favoritesMenuOpen}
                                        onToggleOutsideEvent={
                                            onHandleFavoritesToggleOutside
                                        }
                                        yOffset='18px'
                                        width='150px'>
                                        <FavoritesMenu />
                                    </Dropdown>
                                </li>
                            </>
                        ) : null}
                        <li className={cx('nav__item--group')}>
                            <LocationCard
                                address={getAddress()}
                                onUpdateLocation={onUpdateLocation}
                                onRemoveLocation={onRemoveLocation}
                            />
                            {!authenticated && !calculating ? (
                                <>
                                    <Button
                                        size={
                                            !isMobile && !isTablet
                                                ? 'default'
                                                : 'small'
                                        }
                                        onPress={signInHandler}
                                        label='Sign In'
                                        scroll={false}
                                    />
                                    <Button
                                        size={
                                            !isMobile && !isTablet
                                                ? 'default'
                                                : 'small'
                                        }
                                        theme='secondary'
                                        onPress={signUpHandler}
                                        label='Sign Up'
                                        scroll={false}
                                    />
                                </>
                            ) : (
                                !authenticated && (
                                    <Skeleton
                                        bones={[
                                            {
                                                customClass:
                                                    'hidden lg:block w-[104.44px] h-12',
                                            },
                                            {
                                                customClass:
                                                    'hidden lg:block w-[109.97px] h-12',
                                            },
                                        ]}
                                        theme='light'
                                    />
                                )
                            )}
                            {authenticated && !calculating ? (
                                <>
                                    <IconButton
                                        type={IconType.Bell}
                                        ariaLabel='notifications'
                                        onPress={notificationsHandler}
                                        size='lg'
                                        theme='outline-primary'
                                        transparent
                                    />
                                    <UserButton authenticated={authenticated} />
                                </>
                            ) : (
                                authenticated && (
                                    <Skeleton
                                        bones={[
                                            {
                                                customClass: iconButtonBoneCss,
                                            },
                                            {
                                                customClass: iconButtonBoneCss,
                                            },
                                        ]}
                                        theme='light'
                                        circle
                                    />
                                )
                            )}
                        </li>
                    </ul>
                </nav>
            </div>
            {country ? (
                <div className={cx('__location-details', 'page__gutter')}>
                    <Card
                        icon={IconType.Close}
                        title='Current location:'
                        message={getAddress()}
                        theme='transparent'
                        size='small'
                        onPress={onRemoveLocation}
                        inlineText
                        truncateText
                    />
                </div>
            ) : null}
        </header>
    );
}
