import { useEffect, useState } from 'react';

import { debounce } from '@/utils/helper';

const useDevice = () => {
    const [busy, isBusy] = useState(true);
    const [isMobile, setIsMobile] = useState(true);
    const [isTabletPortrait, setIsTabletPortrait] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    const detectDevice = () => {
        const isMobileDevice = window.matchMedia(
            'only screen and (max-width: 640px)'
        ).matches;
        setIsMobile(isMobileDevice);

        const isTabletDevicePortrait = window.matchMedia(
            'only screen and (min-width:640px) and (max-width: 768px)'
        ).matches;
        setIsTabletPortrait(isTabletDevicePortrait);

        const isTabletDevice = window.matchMedia(
            'only screen and (min-width:640px) and (max-width: 1024px)'
        ).matches;
        setIsTablet(isTabletDevice);

        isBusy(false);
    };

    useEffect(() => {
        const detect = debounce(detectDevice, 100, false);

        if (typeof document !== 'undefined') {
            window.addEventListener('resize', detect);
            detect();
        }

        return () => {
            window.removeEventListener('resize', detect);
        };
    }, []);

    return {
        calculating: busy,
        isMobile,
        isTabletPortrait,
        isTablet,
    };
};

export default useDevice;
