import { useRouter } from 'next/router';
import classNames from 'classnames/bind';

import SmartImage from '@/components/image/SmartImage';
import useDevice from '@/hooks/useDevice';
import { selectApp, AppState } from '@/store/app';

import styles from './styles.module.scss';
import { useAppSelector } from '@/src/store/hooks';

const cx = classNames.bind(styles);

const BackgroundImage = () => {
    const { isMobile, isTablet, calculating } = useDevice();

    const { backgroundImage } = useAppSelector<AppState>(selectApp);

    const router = useRouter();

    const getBackgroundImage = () => {
        if (router.pathname.includes('/contests/')) {
            return backgroundImage
                ? backgroundImage
                : '/images/home/1920_home-page-festival-crowd.jpg';
        }

        return '/images/home/1920_home-page-festival-crowd.jpg';
    };

    if (!calculating && (isMobile || isTablet)) return;

    if (!calculating)
        return router.pathname === '/' ||
            router.pathname.includes('/auth') ||
            router.pathname.includes('/download') ||
            (router.pathname.includes('/contests/') &&
                !router.pathname.includes('/contests/rules')) ? (
            <div className={cx('home-image')} aria-hidden='true'>
                <SmartImage
                    src={getBackgroundImage()}
                    sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 70vw'
                    alt=''
                    width={1920}
                    height={1280}
                    quality={60}
                    fitContent={false}
                    priority
                />
            </div>
        ) : null;
};

export default BackgroundImage;
