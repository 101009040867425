import { ReactNode } from 'react';
import classNames from 'classnames/bind';

import Icon, { IconSize, IconType } from '@/components/icon/Icon';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    icon?: IconType;
    hintIcon?: IconType;
    titleComponent?: ReactNode;
    title?: string;
    message?: string;
    children?: ReactNode;
    onPress?: () => void;
    size: 'small' | 'default' | 'large';
    inline?: boolean;
}

const CardContent: React.FC<Props> = ({
    icon,
    hintIcon,
    titleComponent,
    title,
    message,
    children,
    size,
    inline,
    onPress,
}) => {
    const onPressHandler = () => {
        onPress?.();
    };

    return (
        <div className={cx('__container')}>
            {icon ? (
                <div className={cx('__icon-container')}>
                    <Icon
                        name={icon}
                        size={
                            size !== 'large' ? IconSize.Medium : IconSize.Large
                        }
                    />
                </div>
            ) : null}
            {titleComponent || title || message || children ? (
                <div className={cx('__text-container', { '--inline': inline })}>
                    {titleComponent ? (
                        <div className={cx('__title-component')}>
                            {titleComponent}
                        </div>
                    ) : null}
                    {title ? <p className={cx('__title')}>{title}</p> : null}
                    {message ? <p className={cx('__text')}>{message}</p> : null}
                    {children}
                </div>
            ) : null}
            {hintIcon ? (
                <div className={cx('__button-hint')}>
                    <Icon
                        name={hintIcon}
                        size={
                            size !== 'large' ? IconSize.Medium : IconSize.Large
                        }
                    />
                </div>
            ) : null}
            {onPress ? (
                <button
                    onClick={onPressHandler}
                    className='sr-only'
                    data-expand-click-area>
                    {`Click for more details`}
                </button>
            ) : null}
        </div>
    );
};

export default CardContent;
