import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    toggler: ReactNode;
    children: ReactNode;
    toggleState: boolean;
    onToggleOutsideEvent?: () => void;
    xOffset?: string;
    yOffset?: string;
    width?: string;
    wrap?: boolean;
}

const Dropdown: React.FC<Props> = ({
    toggler,
    children,
    toggleState,
    onToggleOutsideEvent,
    xOffset,
    yOffset,
    width = '256px',
    wrap,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [open, setIsOpen] = useState(toggleState);

    const clickOutsideHandler = (evt) => {
        if (wrapperRef.current) {
            if (!wrapperRef.current.contains(evt.target)) return true;
        }

        return false;
    };

    const closeListener = useCallback(
        (evt) => {
            if (clickOutsideHandler(evt)) {
                window.removeEventListener('click', closeListener);
                if (onToggleOutsideEvent) onToggleOutsideEvent();
                setIsOpen(false);
            }
        },
        [onToggleOutsideEvent]
    );

    const toggleDropdown = useCallback(
        (state) => {
            if (state) {
                setIsOpen(true);

                window.setTimeout(() => {
                    window.removeEventListener('click', closeListener);
                    window.addEventListener('click', closeListener);
                }, 100);
            } else {
                setIsOpen(false);
            }
        },
        [closeListener]
    );

    useEffect(() => {
        setIsOpen(toggleState);
        toggleDropdown(toggleState);

        return () => {
            setIsOpen(false);
            window.removeEventListener('click', closeListener);
        };
    }, [toggleState, closeListener, toggleDropdown]);

    return (
        <div ref={wrapperRef} className={cx('dropdown')}>
            <>{toggler}</>
            {open ? (
                <div
                    role='menu'
                    className={cx('__menu', { '--wrapped': wrap })}
                    style={{
                        width: !wrap && width ? width : undefined,
                        marginLeft: xOffset,
                        marginTop: yOffset,
                    }}>
                    <div className={cx('__inner-container')}>{children}</div>
                </div>
            ) : null}
        </div>
    );
};

export default Dropdown;
