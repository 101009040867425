import classNames from 'classnames/bind';

import Icon, { IconSize, IconType } from '@/components/icon/Icon';
import Link from 'next/link';

import styles from './styles.module.scss';
import { useRouter } from 'next/router';

const cx = classNames.bind(styles);

interface Props {
    iconType: IconType;
    label: string;
    href?: string;
    onClick?: () => void;
}

const AsideButton: React.FC<Props> = ({ href, iconType, label, onClick }) => {
    const router = useRouter();

    const onClickHandler = () => {
        if (href && !onClick) router.push(href);
        if (onClick) onClick();
    };

    return (
        <button
            type='button'
            className={cx('aside-button')}
            onClick={() => onClickHandler()}>
            <span className={cx('__icon')}>
                <Icon name={iconType} size={IconSize.Medium} />
            </span>
            <span className={cx('__label')}>{label}</span>
        </button>
    );
};

export default AsideButton;
