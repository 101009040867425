// TODO: implement use swr to periodically check auth status
// TODO: implement OWASP auth best practices
// TODO: add user idle check hook

import '@/src/styles/globals.css';
import '@/src/styles/styles.scss';
import '@/public/css/nprogress.css';
import '@/public/css/devices.min.css';

import { useEffect, useRef } from 'react';
import { Montserrat, Roboto } from 'next/font/google';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import { useCronitor } from '@cronitorio/cronitor-rum-nextjs';

import Layout from '@/components/layout/index';
import NProgress from 'nprogress';
import { Events, initMetrics, trackEvent } from '@/utils/analytics';
import { wrapper } from '../store';
import $http, { getBearerToken } from '@/utils/http';
import { setAuthState } from '@/common/auth';
import {
    setActiveGenres,
    setFeaturedGenres,
    setMaxUserGenres,
    setUserGenres,
} from '@/store/genre';
import {
    setCrateFestivals,
    setUserArtists,
    setUserFestivals,
} from '@/store/user';
import { parseError } from '@/utils/apiErrorHandler';

const montserrat = Montserrat({
    weight: ['100', '300', '400', '500', '600', '700'],
    subsets: ['latin'],
    variable: '--font-montserrat',
    display: 'swap',
});

const roboto = Roboto({
    weight: ['400', '500', '700', '900'],
    subsets: ['latin'],
    variable: '--font-roboto',
    display: 'swap',
});

const env = process?.env?.NEXT_PUBLIC_APP_ENV;

const App = ({ Component, ...rest }) => {
    const router = useRouter();
    const mounted = useRef(false);
    const { store, props } = wrapper.useWrappedStore(rest);
    const { pageProps } = props;

    useCronitor(process.env.NEXT_PUBLIC_CRONITOR_KEY ?? '', {
        enabled: env === 'production',
        environment: env,
    });

    const getLayout =
        Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

    useEffect(() => {
        const handleRouteChange = () => {
            NProgress.start();
        };

        const handleRouteChangeComplete = (url) => {
            trackEvent(`${Events.ViewedPage}${url}`, { path: url });
            NProgress.done();
        };

        const handleRouteChangeError = () => NProgress.done();

        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        router.events.on('routeChangeError', handleRouteChangeError);
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
            router.events.off('routeChangeError', handleRouteChangeError);
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);

    useEffect(() => {
        if (!mounted.current) {
            trackEvent(`${Events.ViewedPage}${window.location.pathname}`, {
                path: window.location.pathname,
            });

            if (typeof window !== 'undefined') {
                initMetrics(
                    async () => {
                        if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
                            const amplitude = await import(
                                '@amplitude/analytics-browser'
                            );

                            if (amplitude) {
                                amplitude.init(
                                    process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
                                    {
                                        defaultTracking: true,
                                        minIdLength: 1,
                                    }
                                );
                            }
                        }

                        let Sentry;

                        if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
                            Sentry = await import('@sentry/react');

                            if (Sentry) {
                                Sentry.init({
                                    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
                                    environment: env,
                                    tracesSampleRate: 0.2,
                                    tracePropagationTargets: [
                                        /^https:\/\/staging.festgps\.app\/api/,
                                        /^https:\/\/festgps\.app\/api/,
                                    ],
                                    debug: false,
                                    replaysOnErrorSampleRate: 0.2,
                                    replaysSessionSampleRate: 0.1,
                                    integrations: [new Sentry.BrowserTracing()],
                                });
                            }
                        }

                        if (process.env.NEXT_PUBLIC_LOGROCKET_APP_ID) {
                            const LogRocket = (await import('logrocket'))
                                .default;

                            if (LogRocket) {
                                const logRocketNetworkConfig = {
                                    requestSanitizer: (request: any) => {
                                        if (
                                            request?.url
                                                ?.toLowerCase()
                                                ?.indexOf('/auth') !== -1 ||
                                            request?.url
                                                ?.toLowerCase()
                                                ?.indexOf('/account') !== -1
                                        ) {
                                            return null;
                                        }

                                        if (request.headers['Authorization']) {
                                            request.headers['Authorization'] =
                                                '***';
                                        }

                                        if (
                                            request.headers['x-festgps-api-key']
                                        ) {
                                            request.headers[
                                                'x-festgps-api-key'
                                            ] = '***';
                                        }

                                        return request;
                                    },
                                    responseSanitizer: (response: any) => {
                                        if (response?.body?.['access_token']) {
                                            response.body['access_token'] =
                                                '***';
                                        }

                                        if (response?.body?.['refresh_token']) {
                                            response.body['refresh_token'] =
                                                '***';
                                        }

                                        if (response?.body?.['token_type']) {
                                            response.body['token_type'] = '***';
                                        }

                                        return response;
                                    },
                                };

                                LogRocket.init(
                                    process.env.NEXT_PUBLIC_LOGROCKET_APP_ID,
                                    {
                                        network: logRocketNetworkConfig,
                                    }
                                );

                                LogRocket.getSessionURL((sessionURL) => {
                                    if (Sentry) {
                                        Sentry.configureScope((scope) => {
                                            scope.setExtra(
                                                'sessionURL',
                                                sessionURL
                                            );
                                        });
                                    }
                                });
                            }
                        }
                    },
                    env === 'production' || env === 'staging'
                );
            }
        }

        mounted.current = true;
    }, []);

    return (
        <Provider store={store}>
            <div
                id='festgps-app'
                className={`${montserrat.variable} ${roboto.variable} env--${env}`}>
                {getLayout(<Component {...pageProps} />)}
            </div>
        </Provider>
    );
};

App.getInitialProps = wrapper.getInitialAppProps(
    (store) =>
        async ({ Component, ctx }) => {
            let pageProps = {};

            try {
                const req = ctx.req;
                const userAgent = req?.headers?.['user-agent'];
                const pathname = ctx.pathname;
                const { user: userStore } = ctx.store.getState();
                const { userArtists, userCrate, userFestivals } = userStore;

                const router = {
                    asPath: ctx.asPath ?? '',
                    pathname,
                    query: ctx.query,
                };

                const baseUrl =
                    process.env.APP_ORIGIN ??
                    process.env.NEXT_PUBLIC_APP_ORIGIN;
                const bearerToken = getBearerToken(req);

                const featuredGenresData =
                    (await $http.apiGet({
                        endpoint: '/genres',
                        params: {
                            _sort: 'name:ASC',
                            featured_eq: true,
                        },
                        useToken: false,
                        userAgent,
                    })) ?? [];

                if (featuredGenresData)
                    store.dispatch(setFeaturedGenres(featuredGenresData));

                if (bearerToken) {
                    const user = await $http.apiGet({
                        endpoint: '/users/me',
                        bearerToken,
                        useToken: true,
                        rateLimit: false,
                    });

                    const { genres, max } =
                        (await $http.apiGet({
                            endpoint: '/genres/me',
                            bearerToken,
                            useToken: true,
                        })) ?? [];

                    store.dispatch(setUserGenres(genres));
                    store.dispatch(setMaxUserGenres(max));

                    if (
                        userArtists &&
                        !userArtists.length &&
                        !pathname.includes('/user/settings')
                    ) {
                        const userArtistsData =
                            (await $http.apiGet({
                                endpoint: '/user-artists',
                                bearerToken,
                                useToken: !bearerToken ? false : true,
                                userAgent,
                            })) ?? [];

                        if (userArtistsData)
                            store.dispatch(setUserArtists(userArtistsData));
                    }

                    if (
                        userFestivals &&
                        !userFestivals.length &&
                        !pathname.includes('/user/settings')
                    ) {
                        const userFestivalsData =
                            (await $http.apiGet({
                                endpoint: '/user-festivals',
                                bearerToken,
                                useToken: !bearerToken ? false : true,
                                userAgent,
                            })) ?? [];

                        if (userFestivalsData)
                            store.dispatch(setUserFestivals(userFestivalsData));
                    }

                    if (
                        userCrate &&
                        !userCrate.length &&
                        !pathname.includes('/user/settings')
                    ) {
                        const userCrateData =
                            (await $http.apiGet({
                                endpoint: '/user-crates',
                                bearerToken,
                                useToken: !bearerToken ? false : true,
                                userAgent,
                            })) ?? [];

                        if (userCrateData)
                            store.dispatch(setCrateFestivals(userCrateData));
                    }

                    setAuthState({
                        store,
                        user,
                        router,
                        baseUrl: baseUrl ?? '',
                    });
                }
            } catch (error) {
                parseError({ error });
            }

            if (Component.getInitialProps) {
                pageProps = (await Component.getInitialProps(ctx)) || {};
            }

            return { pageProps };
        }
);

export default App;
