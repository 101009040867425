import classNames from 'classnames/bind';

import styles from './styles.module.scss';
import AsideButton from './AsideButton';
import { Events, trackEvent } from '@/utils/analytics';
import { IconType } from '@/components/icon/Icon';
import { setSearchOpen } from '@/store/app';
import { useAppDispatch } from '@/store/hooks';

const cx = classNames.bind(styles);

const AppAside: React.FC = () => {
    const dispatch = useAppDispatch();

    const onDownloadHandler = () => {
        trackEvent(Events.ClickedGetTheApp, {
            aside: true,
            path: window.location.pathname,
        });

        window.open(
            'https://links.festgps.app/download',
            '_blank',
            'noopener noreferrer'
        );
    };

    const onSearchHandler = () => {
        trackEvent(Events.ClickedSearch, { path: window.location.pathname });
        dispatch(setSearchOpen(true));
    };

    return (
        <aside className={cx('app-aside')}>
            <ul className={cx('__nav')}>
                <li className={cx('__item')}>
                    <AsideButton
                        iconType={IconType.Home}
                        label='Home'
                        href='/'
                    />
                </li>
                <li className={cx('__item')}>
                    <AsideButton
                        iconType={IconType.Search}
                        label='Search'
                        onClick={onSearchHandler}
                    />
                </li>
                <li className={cx('__item')}>
                    <AsideButton
                        iconType={IconType.PartyPopper}
                        label='Festivals'
                        href='/festivals'
                    />
                </li>
                <li className={cx('__item')}>
                    <AsideButton
                        iconType={IconType.Download}
                        label='Get App'
                        onClick={onDownloadHandler}
                    />
                </li>
            </ul>
        </aside>
    );
};

export default AppAside;
