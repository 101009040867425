import { useEffect } from 'react';
import classNames from 'classnames/bind';
import dynamic from 'next/dynamic';

import AppHeader from '@/components/chrome/header/AppHeader';
import AppAside from '@/components/chrome/aside/AppAside';
import BackgroundImage from '@/components/chrome/background-image/BackgroundImage';
import { initMetrics } from '@/utils/analytics';
import {
    selectApp,
    setToastOpen,
    setToastData,
    AppState,
    setGenericModalOpen,
    setGenericModalData,
} from '@/store/app';
import { UserState, selectUser } from '@/store/user';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const AppMenu = dynamic(
    () => import('@/components/chrome/mobile-menu/AppMenu')
);
const AppFooter = dynamic(() => import('@/components/chrome/footer/AppFooter'));
const CalendarShareModal = dynamic(
    () => import('@/components/share/calendar/CalendarShareModal')
);
const DownloadBanner = dynamic(
    () => import('@/components/download-banner/DownloadBanner')
);

const GenericModal = dynamic(() => import('@/components/modal/generic/Modal'));
const ImageModal = dynamic(() => import('@/components/modal/image/Modal'));
const SearchModal = dynamic(() => import('@/components/search/SearchModal'));
const ShareModal = dynamic(
    () => import('@/components/share/social/ShareModal')
);
const Toast = dynamic(() => import('@/components/indicators/toast/Toast'));
const VerifyEmailModal = dynamic(
    () => import('@/components/auth/verify-email-modal/VerifyEmailModal')
);

import PostText from '@/components/post/PostText';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export default function Layout({ children }) {
    const dispatch = useAppDispatch();

    const { id, country } = useAppSelector<UserState>(selectUser);
    const {
        menuOpen,
        calendarShareOpen,
        imageModalOpen,
        genericModalOpen,
        genericModalData,
        shareOpen,
        searchOpen,
        toastOpen,
        toastData,
        verifyEmailOpen,
    } = useAppSelector<AppState>(selectApp);

    const onDismissToastHandler = () => {
        dispatch(
            setToastData({ type: null, message: null, dismissTimer: null })
        );
        dispatch(setToastOpen(false));
    };

    const closeGenericModal = () => {
        dispatch(setGenericModalOpen(false));
        dispatch(setGenericModalData({ title: '', body: '' }));
    };

    useEffect(() => {
        (async () => {
            const env = process?.env?.NEXT_PUBLIC_APP_ENV;

            if (id) {
                initMetrics(
                    async () => {
                        const amplitude = await import(
                            '@amplitude/analytics-browser'
                        );
                        if (amplitude) amplitude.setUserId(`${id}`);

                        const LogRocket = (await import('logrocket')).default;
                        if (LogRocket) LogRocket.identify(`${id}`);
                    },
                    env === 'staging' || env === 'production'
                );
            }
        })();
    }, [id]);

    return (
        <div className={cx('wrapper', 'font-montserrat')}>
            <AppHeader />
            <AppAside />
            <main
                className={cx('content-container', 'page__gutter', {
                    '--has-location': country,
                })}>
                {children}
            </main>
            <DownloadBanner />
            <AppFooter />
            {menuOpen ? <AppMenu /> : null}
            {verifyEmailOpen ? <VerifyEmailModal /> : null}
            {searchOpen ? <SearchModal /> : null}
            {shareOpen ? <ShareModal /> : null}
            {calendarShareOpen ? <CalendarShareModal /> : null}
            {genericModalOpen ? (
                <GenericModal
                    title={genericModalData.title}
                    markdownContent={genericModalData.hasMarkdownContent}
                    onClose={closeGenericModal}>
                    <PostText text={genericModalData.body} />
                </GenericModal>
            ) : null}
            {imageModalOpen ? <ImageModal /> : null}
            {toastOpen ? (
                <Toast
                    type={toastData.type}
                    message={toastData.message}
                    dismissTimer={toastData.dismissTimer}
                    onDismiss={onDismissToastHandler}
                />
            ) : null}
            <div className={cx('gradient')} />
            <BackgroundImage />
        </div>
    );
}
