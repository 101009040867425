import { useRef } from 'react';

import classNames from 'classnames/bind';

import FormWrapper from '@/components/form/FormWrapper';
import Icon, { IconSize, IconType } from '@/components/icon/Icon';
import { MIN_CHARS_FOR_SEARCH } from '@/utils/constants';
import { setSearchOpen } from '@/store/app';
import { useAppDispatch } from '@/store/hooks';

import styles from './styles.module.scss';
import { Events, trackEvent } from '@/utils/analytics';

const cx = classNames.bind(styles);

interface Props {
    busy?: boolean;
    isButton?: boolean;
    placeholder: string;
    onChangeHandler?: (text: string) => void;
    onCloseHandler?: () => void;
}

const SearchBar: React.FC<Props> = ({
    busy = false,
    isButton = true,
    placeholder,
    onChangeHandler,
    onCloseHandler,
}) => {
    const dispatch = useAppDispatch();
    const typingTimer = useRef<number>();

    const onChangeTextHandler = (evt) => {
        const query = evt.target.value;

        if (typingTimer.current) window.clearInterval(typingTimer.current);

        typingTimer.current = window.setInterval(() => {
            window.clearInterval(typingTimer.current);
            if (onChangeHandler) onChangeHandler(query);
        }, 500);
    };

    const onSubmitHandler = (evt) => {
        evt.preventDefault();
    };

    const showSearch = () => {
        trackEvent(Events.ClickedSearch, { path: window.location.pathname });
        dispatch(setSearchOpen(true));
    };

    return (
        <div className={cx('search-bar-container')}>
            {isButton ? (
                <button
                    type='button'
                    className={cx('search-bar-toggle')}
                    onClick={showSearch}
                    aria-label='Open search form'
                />
            ) : null}
            <FormWrapper
                id='global-search'
                name='global-search'
                className={cx('search-bar', { 'search-bar__button': isButton })}
                onSubmitHandler={onSubmitHandler}>
                <input
                    id='global-search-input'
                    name='global-search-input'
                    placeholder={placeholder}
                    className={cx('search-input', {
                        'search-input--button': isButton,
                    })}
                    onChange={onChangeTextHandler}
                    minLength={MIN_CHARS_FOR_SEARCH}
                    disabled={busy || isButton}
                    aria-label='Global site search input field'
                    required
                    autoFocus
                />
                <div className={cx('search-buttons-container')}>
                    <button
                        type='submit'
                        className={cx('search-button')}
                        disabled={busy || isButton}
                        aria-label='Start search button'>
                        <Icon name={IconType.Search} size={IconSize.Small} />
                    </button>
                    {!isButton ? (
                        <button
                            type='button'
                            className={cx('search-button')}
                            onClick={onCloseHandler}
                            aria-label='Start search button'>
                            <Icon name={IconType.Close} size={IconSize.Small} />
                        </button>
                    ) : null}
                </div>
            </FormWrapper>
        </div>
    );
};

export default SearchBar;
