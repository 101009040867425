import { parseCookies } from 'nookies';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { parseError } from './apiErrorHandler';
import { IncomingMessage } from 'http';

const apiUrl = process.env.API_URL;

type CacheObj = {
    data: string;
    createdAt: number;
};

type CacheMap = Record<string, CacheObj>;

const cacheMap: CacheMap = {};
const queue: string[] = [];

const inQueue = (value: string) => queue.includes(value);

const addToQueue = (value: string) => {
    if (inQueue(value)) {
        return;
    } else {
        queue.push(value);
    }
};

const removeFromQueue = (value: string) => {
    const qIdx = queue.findIndex((q) => q === value);
    queue.splice(qIdx, 1);
};

export const getIpAddress = async (): Promise<string | undefined> => {
    try {
        return await get({
            endpoint: 'https://api.ipify.org',
            signal: AbortSignal.timeout(3000),
            cache: true,
            throwError: false,
        });
    } catch (error) {
        parseError({ error, throwError: false, trackError: false });
        return undefined;
    }
};

const setHttpHeaders = async (userAgent?: string) => {
    const ipAddress = await getIpAddress();

    axios.defaults.headers.common['x-festgps-app-name'] = 'festgps';
    axios.defaults.headers.common['x-festgps-app-platform'] = 'web';
    axios.defaults.headers.common['x-festgps-real-ip'] = ipAddress ?? '';
    axios.defaults.headers.post['Content-Type'] =
        'application/x-www-form-urlencoded';

    if (userAgent) axios.defaults.headers.common['user-agent'] = userAgent;
};

const generateCacheKey = (
    url: string,
    params?: Record<string, unknown> | QyParams,
    useToken: boolean = false
) => {
    return JSON.stringify(
        `${url}${qs.stringify(params, {
            addQueryPrefix: true,
        })}-useToken=${useToken}`
    );
};

export const addCache = (key: string, data: Record<string, unknown>): void => {
    cacheMap[key] = { data: JSON.stringify(data), createdAt: Date.now() };
};

export const hasCache = (key: string, ttl = 300): boolean => {
    if (cacheMap?.[key]) {
        const now = Date.now();
        const cacheObj: CacheObj = cacheMap[key];
        const elapsedTime = Math.floor((now - cacheObj.createdAt) / 1000);

        if (elapsedTime >= ttl) {
            // 5 minutes
            return false;
        }

        return cacheMap?.[key] ? true : false;
    }

    return false;
};

export const getCache = (key: string): Record<string, unknown> | undefined => {
    if (cacheMap?.[key]) {
        const cacheObj: CacheObj = cacheMap?.[key];
        return JSON.parse(cacheObj.data);
    }
};

export const get = async ({
    endpoint,
    params,
    baseUrl,
    transformResponse,
    bearerToken,
    useToken,
    timeout,
    signal,
    cache = false,
    cacheTtl = 300,
    rateLimit = true,
    requestKey,
    throwError = true,
    headers,
}: HTTPRequestProps) => {
    try {
        const config: AxiosRequestConfig = { params };

        if (bearerToken && useToken) {
            config.headers = {
                Authorization: `Bearer ${bearerToken}`,
            };
        }

        const isExternal = endpoint.indexOf('http') > -1 ? true : false;
        let url = !isExternal ? `/api${endpoint}` : endpoint;

        if (!isExternal && baseUrl) url = `${baseUrl}/api${endpoint}`;

        const cacheKey = requestKey ?? generateCacheKey(url, params, useToken);

        if (rateLimit) {
            if (inQueue(cacheKey)) return;
            addToQueue(cacheKey);
        }

        let result;
        let resp: AxiosResponse;

        if (cache && hasCache(cacheKey, cacheTtl)) {
            result = getCache(cacheKey);
        } else {
            if (transformResponse) config.transformResponse = transformResponse;
            if (timeout) config.timeout = timeout;
            if (signal) config.signal = signal;
            if (headers) config.headers = headers;

            resp = await axios.get(url, config);
            result = resp?.data;

            if (cache && result) addCache(cacheKey, result);
        }

        if (rateLimit) removeFromQueue(cacheKey);

        return result;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const post = async ({
    endpoint,
    params = {},
    baseUrl,
    rateLimit = false,
    requestKey,
    throwError = true,
}: HTTPRequestProps) => {
    try {
        const isExternal = endpoint.indexOf('http') > -1 ? true : false;
        let url = !isExternal ? `/api${endpoint}` : endpoint;

        if (!isExternal && baseUrl) {
            url = `${baseUrl}/api${endpoint}`;
        }

        const cacheKey = requestKey ?? generateCacheKey(url, params);

        if (rateLimit) {
            if (inQueue(cacheKey)) return;
            addToQueue(cacheKey);
        }

        const { data } = await axios.post(url, params);

        if (rateLimit) removeFromQueue(cacheKey);

        return data;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const put = async ({
    endpoint,
    params = {},
    baseUrl,
    throwError = true,
}: HTTPRequestProps) => {
    try {
        const isExternal = endpoint.indexOf('http') > -1 ? true : false;
        let url = !isExternal ? `/api${endpoint}` : endpoint;

        if (!isExternal && baseUrl) {
            url = `${baseUrl}/api${endpoint}`;
        }

        const { data } = await axios.put(url, params);
        return data;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const remove = async ({
    endpoint,
    params,
    baseUrl,
    throwError = true,
}: HTTPRequestProps) => {
    try {
        const config: AxiosRequestConfig = { params };
        const isExternal = endpoint.indexOf('http') > -1 ? true : false;

        let url = !isExternal ? `/api${endpoint}` : endpoint;

        if (!isExternal && baseUrl) {
            url = `${baseUrl}/api${endpoint}`;
        }

        const { data } = await axios.delete(url, config);
        return data;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const apiPost = async ({
    endpoint,
    params = {},
    bearerToken,
    userAgent,
    useToken,
    rateLimit = false,
    requestKey,
    throwError = true,
}: HTTPRequestProps) => {
    await setHttpHeaders(userAgent);

    const config: AxiosRequestConfig = {};

    if (bearerToken && useToken) {
        config.headers = {
            Authorization: `Bearer ${bearerToken}`,
        };
    }

    try {
        const url = `${apiUrl}${endpoint}`;
        const cacheKey = requestKey ?? generateCacheKey(url, params);

        if (rateLimit) {
            if (inQueue(cacheKey)) return;
            addToQueue(cacheKey);
        }

        const { data } = await axios.post(url, params, config);

        if (rateLimit) removeFromQueue(cacheKey);

        return data;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const apiGet = async ({
    endpoint,
    params = {},
    transformResponse,
    bearerToken,
    cache = false,
    cacheTtl = 300,
    rateLimit = true,
    requestKey,
    useToken,
    userAgent,
    throwError = true,
}: HTTPRequestProps) => {
    const config: AxiosRequestConfig = { params };
    const isExternal = endpoint.indexOf('http') > -1 ? true : false;

    const url = !isExternal ? `${apiUrl}${endpoint}` : endpoint;
    const cacheKey = requestKey ?? generateCacheKey(url, params, useToken);

    await setHttpHeaders(userAgent);

    if (bearerToken && useToken) {
        config.headers = {
            Authorization: `Bearer ${bearerToken}`,
        };
    }

    try {
        let result;
        let resp: AxiosResponse;

        if (rateLimit) {
            if (inQueue(cacheKey)) return;
            addToQueue(cacheKey);
        }

        if (cache && hasCache(cacheKey, cacheTtl)) {
            result = getCache(cacheKey);
        } else {
            if (transformResponse) config.transformResponse = transformResponse;

            resp = await axios.get(url, config);
            result = resp?.data;

            if (cache && result) addCache(cacheKey, result);
        }

        if (rateLimit) removeFromQueue(cacheKey);

        return result;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const apiPut = async ({
    endpoint,
    params = {},
    bearerToken,
    userAgent,
    useToken,
    throwError = true,
}: HTTPRequestProps) => {
    await setHttpHeaders(userAgent);

    const config: AxiosRequestConfig = {};

    if (bearerToken && useToken) {
        config.headers = {
            Authorization: `Bearer ${bearerToken}`,
        };
    }

    try {
        const { data } = await axios.put(
            `${apiUrl}${endpoint}`,
            params,
            config
        );

        return data;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const apiRemove = async ({
    endpoint,
    params,
    bearerToken,
    userAgent,
    useToken,
    throwError = true,
}: HTTPRequestProps) => {
    await setHttpHeaders(userAgent);

    const config: AxiosRequestConfig = { params };

    if (bearerToken && useToken) {
        config.headers = {
            Authorization: `Bearer ${bearerToken}`,
        };
    }

    try {
        const { data } = await axios.delete(`${apiUrl}${endpoint}`, config);

        return data;
    } catch (e) {
        if (throwError) {
            throw e;
        } else {
            return e;
        }
    }
};

export const isAuthenticated = (req) => {
    if (req && process.env.SESSION_COOKIE_NAME) {
        const cookies = parseCookies({ req });
        return cookies?.[process.env.SESSION_COOKIE_NAME] ? true : false;
    }

    return false;
};

export const getBearerToken = (
    req: IncomingMessage | undefined
): string | undefined => {
    let cookies;

    if (req) {
        cookies = parseCookies({ req });
    } else {
        cookies = parseCookies();
    }

    return process.env.SESSION_COOKIE_NAME
        ? cookies?.[process.env.SESSION_COOKIE_NAME]
        : undefined;
};

export const gatePage = (req) => {
    if (!isAuthenticated(req)) {
        return {
            redirect: {
                destination: '/auth/sign-in',
                permanent: false,
            },
        };
    }

    return false;
};

export const redirectIfAuthenticated = (req) => {
    if (isAuthenticated(req)) {
        return {
            redirect: {
                destination: '/',
                permanent: false,
            },
        };
    }

    return false;
};

const http = {
    get,
    post,
    put,
    remove,
    apiGet,
    apiPost,
    apiPut,
    apiRemove,
    isAuthenticated,
    gatePage,
};

export default http;
