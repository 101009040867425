import classNames from 'classnames/bind';

import Card from '@/components/containers/card/Card';
import IconButton from '@/components/icon/IconButton';
import useDevice from '@/hooks/useDevice';
import { IconType } from '@/components/icon/Icon';
import { AppState, selectApp } from '@/store/app';
import { useAppSelector } from '@/store/hooks';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    address: string;
    onUpdateLocation: () => void;
    onRemoveLocation: () => void;
}

const LocationCard: React.FC<Props> = ({
    address,
    onUpdateLocation,
    onRemoveLocation,
}) => {
    const { calculating, isMobile, isTablet } = useDevice();
    const { geoLocating } = useAppSelector<AppState>(selectApp);

    return (
        <div className={cx('__location-card')}>
            {address && !isMobile && !isTablet ? (
                <Card
                    icon={IconType.Close}
                    title='Current location'
                    message={address}
                    theme='transparent'
                    size='small'
                    onPress={onRemoveLocation}
                    busy={geoLocating}
                    truncateText
                    wrap
                />
            ) : !calculating && !isMobile && !isTablet ? (
                <Card
                    icon={IconType.Location}
                    title='Enable location'
                    message='See festivals nearby'
                    theme='outline'
                    size='small'
                    onPress={onUpdateLocation}
                    busy={geoLocating}
                    truncateText
                    wrap
                />
            ) : !calculating && (isMobile || isTablet) ? (
                <IconButton
                    type={!address ? IconType.Location : IconType.LocationOff}
                    ariaLabel='Get current location'
                    onPress={!address ? onUpdateLocation : onRemoveLocation}
                    size='lg'
                    theme='outline-primary'
                    busy={geoLocating}
                    transparent
                />
            ) : null}
        </div>
    );
};

export default LocationCard;
