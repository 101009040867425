import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Dropdown from '@/components/form/dropdown/Dropdown';
import UserMenu from '@/components/chrome/user-menu/UserMenu';
import { IconType } from '@/components/icon/Icon';
import IconButton from '@/components/icon/IconButton';
import { goSignUp } from '@/common/auth';
import useEsc from '@/hooks/useEsc';

interface Props {
    authenticated: boolean;
}

const UserButton: React.FC<Props> = ({ authenticated }) => {
    const router = useRouter();

    const [userMenuOpen, setUserMenuOpen] = useState(false);

    const toggleUserMenu = () => {
        if (authenticated) {
            if (!userMenuOpen) {
                setUserMenuOpen(true);
            } else {
                setUserMenuOpen(false);
            }
        } else {
            goSignUp();
        }
    };

    const toggleOutsideEventHandler = () => setUserMenuOpen(false);

    useEsc({ escHandler: toggleOutsideEventHandler });

    useEffect(() => {
        const handleRouteChange = () => setUserMenuOpen(false);
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events]);

    return (
        <Dropdown
            toggler={
                <IconButton
                    type={IconType.User}
                    ariaLabel='User menu'
                    onPress={toggleUserMenu}
                    size='lg'
                    theme='primary'
                />
            }
            onToggleOutsideEvent={toggleOutsideEventHandler}
            toggleState={userMenuOpen}>
            <UserMenu />
        </Dropdown>
    );
};

export default UserButton;
