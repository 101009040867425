import { useRef } from 'react';

import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    children?: JSX.Element;
    enabled?: boolean;
    active?: boolean;
    height?: 'auto' | 'full';
}

const A11yButton: React.FC<Props> = ({
    children,
    enabled = true,
    active,
    height = 'full',
}) => {
    const refExpandedArea = useRef<HTMLDivElement>(null);
    const clickableElemTypes = ['a', 'button'];

    const clickHandler = (e) => {
        if (refExpandedArea.current) {
            const divElem = refExpandedArea.current;
            const elem = e.target.closest('a,button');
            const clickableElems: HTMLButtonElement[] = [
                ...divElem.querySelectorAll<HTMLButtonElement>(
                    '[data-expand-click-area]'
                ),
            ];

            if (clickableElems.length !== 1) {
                throw new Error(
                    `Expected one clickable element but found ${clickableElems.length}`
                );
            }

            const clickableElem = clickableElems[0];
            const targetIsClickable = clickableElemTypes.includes(
                e.target?.tagName?.toLowerCase()
            );

            if (!elem && clickableElem !== e.target && !targetIsClickable) {
                clickableElem.click();
            }
        }
    };

    return (
        <div
            ref={refExpandedArea}
            className={cx(
                'a11y__button',
                `--height-${height}`,
                {
                    '--active': active,
                },
                { '--enabled': enabled }
            )}
            tabIndex={0}
            onClick={clickHandler}>
            {children}
        </div>
    );
};

export default A11yButton;
