import classNames from 'classnames/bind';

import ActiveLink from '@/components/active-link/ActiveLink';
import Icon, { IconType } from '@/components/icon/Icon';
import { UserState, selectUser } from '@/store/user';
import { useAppSelector } from '@/store/hooks';

import dropdownStyles from '@/components/form/dropdown/styles.module.scss';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);
const cxDropDown = classNames.bind(dropdownStyles);

const UserMenu: React.FC = () => {
    const { firstName, lastName, email } =
        useAppSelector<UserState>(selectUser);

    return (
        <div className={cx('user-menu')}>
            <div className={cx('__user-details')}>
                <ActiveLink
                    href='/user/settings/manage-account'
                    className={cx('nav__item')}
                    activeClassName={cx('nav__item--active')}>
                    <span className={cx('__user-fullname')}>
                        {firstName} {lastName}
                    </span>
                    {email ? (
                        <span className={cx('__user-email')}>{email}</span>
                    ) : null}
                </ActiveLink>
            </div>
            <ul className={cxDropDown('__menu-section')}>
                <li className={cxDropDown('__menu-section__label')}>
                    <span className={cxDropDown('nav__item-icon')}>
                        <Icon name={IconType.Favorite} />
                    </span>
                    <span>Favorites</span>
                </li>
                <li>
                    <ActiveLink
                        href='/user/favorites/artists'
                        className={cxDropDown('nav__item')}
                        activeClassName={cxDropDown('nav__item--active')}>
                        <span className={cxDropDown('nav__item-icon')}>
                            <Icon name={IconType.Artist} />
                        </span>
                        <span>Artists</span>
                    </ActiveLink>
                </li>
                <li>
                    <ActiveLink
                        href='/user/favorites/festivals'
                        className={cxDropDown('nav__item')}
                        activeClassName={cxDropDown('nav__item--active')}>
                        <span className={cxDropDown('nav__item-icon')}>
                            <Icon name={IconType.PartyPopper} />
                        </span>
                        <span>Festivals</span>
                    </ActiveLink>
                </li>
            </ul>
            <ul className={cxDropDown('__menu-section')}>
                <li className={cxDropDown('__menu-section__label')}>
                    <span className={cxDropDown('nav__item-icon')}>
                        <Icon name={IconType.Award} />
                    </span>
                    <span>Recommended</span>
                </li>
                <li>
                    <ActiveLink
                        href='/user/recommended/festivals'
                        className={cxDropDown('nav__item')}
                        activeClassName={cxDropDown('nav__item--active')}>
                        <span className={cxDropDown('nav__item-icon')}>
                            <Icon name={IconType.PartyPopper} />
                        </span>
                        <span>Festivals</span>
                    </ActiveLink>
                </li>
            </ul>
            <ul className={cxDropDown('__menu-section')}>
                <li>
                    <ActiveLink
                        href='/user/compare'
                        className={cxDropDown('nav__item')}
                        activeClassName={cxDropDown('nav__item--active')}>
                        <span className={cxDropDown('nav__item-icon')}>
                            <Icon name={IconType.Compare} />
                        </span>
                        <span>Compare</span>
                    </ActiveLink>
                </li>
            </ul>
            <ul className={cxDropDown('__menu-section')}>
                <li>
                    <ActiveLink
                        href='/user/notifications'
                        className={cxDropDown('nav__item')}
                        activeClassName={cxDropDown('nav__item--active')}>
                        <span className={cxDropDown('nav__item-icon')}>
                            <Icon name={IconType.Bell} />
                        </span>
                        <span>Notifications</span>
                    </ActiveLink>
                </li>
                <li>
                    <ActiveLink
                        href='/user/settings/manage-account'
                        className={cxDropDown('nav__item')}
                        activeClassName={cxDropDown('nav__item--active')}>
                        <span className={cxDropDown('nav__item-icon')}>
                            <Icon name={IconType.Settings} />
                        </span>
                        <span>Settings</span>
                    </ActiveLink>
                </li>
            </ul>
            <ul className={cxDropDown('__menu-section')}>
                <li>
                    <ActiveLink
                        href='/auth/sign-out'
                        className={cxDropDown('nav__item')}
                        activeClassName={cxDropDown('nav__item--active')}>
                        <span className={cxDropDown('nav__item-icon')}>
                            <Icon name={IconType.Logout} />
                        </span>
                        <span>Sign out</span>
                    </ActiveLink>
                </li>
            </ul>
        </div>
    );
};

export default UserMenu;
