import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image, { type ImageProps } from 'next/image';
import classNames from 'classnames/bind';

import { debounce } from '@/utils/helper';
import Skeleton from '@/components/loading/skeleton/Skeleton';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props extends ImageProps {
    fitContent?: boolean;
    decorative?: boolean;
    imgClassName?: string;
    skeleton?: boolean;
    skeletonTheme?: 'light' | 'dark';
    skeletonTransparent?: boolean;
    animateSkeleton?: boolean;
    circle?: boolean;
    busy?: boolean;
    onLoaded?: () => void;
}

const SmartImage: React.FC<Props> = ({
    src,
    alt,
    className,
    width,
    height,
    quality,
    sizes,
    priority,
    fitContent = true,
    imgClassName,
    skeleton = true,
    skeletonTheme = 'light',
    skeletonTransparent = false,
    animateSkeleton = true,
    circle,
    busy = false,
    onLoaded,
}) => {
    const containerElem = useRef<HTMLDivElement>(null);
    const [loaded, isLoaded] = useState(false);
    const [inFrame, isInFrame] = useState(false);
    const [aboveFold, isAboveFold] = useState(false);

    const onLoadHandler = () => {
        onLoaded?.();
        isLoaded(true);
    };

    const loadImage = useCallback(async (): Promise<boolean> => {
        const container = containerElem.current;

        if (container) {
            if (typeof window !== 'undefined') {
                if (priority) return true;

                if ('IntersectionObserver' in window) {
                    return new Promise((resolve) => {
                        const observer = new IntersectionObserver((entries) => {
                            const ratio = entries[0].intersectionRatio;

                            if (ratio <= 0) resolve(false);
                            if (container && ratio > 0.2) {
                                isInFrame(true);
                                observer.unobserve(container);
                                resolve(true);
                            }
                        });

                        if (container) observer.observe(container);
                    });
                } else {
                    isInFrame(true);
                    return true;
                }
            }
        }
        return false;
    }, [priority]);

    useEffect(() => {
        const viewportListener = debounce(loadImage, 100, null);
        const container = containerElem.current;
        const carousel =
            container?.closest('[class*="slides"]') ||
            container?.closest('[class*="masonry_container"]');

        (async () => {
            if (typeof window !== 'undefined' && !busy) {
                if (carousel)
                    carousel.addEventListener('scroll', viewportListener);

                document.addEventListener('scroll', viewportListener);
                window.addEventListener('resize', viewportListener);
                window.addEventListener('orientationchange', viewportListener);

                const isVisible = await loadImage();
                isAboveFold(isVisible);
            }
        })();

        return () => {
            if (typeof window !== 'undefined') {
                if (carousel)
                    carousel.removeEventListener('scroll', viewportListener);

                document.removeEventListener('scroll', viewportListener);
                window.removeEventListener('resize', viewportListener);
                window.removeEventListener(
                    'orientationchange',
                    viewportListener
                );
            }
        };
    }, [busy, loadImage]);

    return (
        <div
            ref={containerElem}
            className={cx(
                'smart-image',
                { '--loaded': loaded },
                { '--visible': inFrame },
                { '--circle': circle },
                { '--fit-content': fitContent },
                className
            )}>
            {(inFrame || priority) && !busy ? (
                <Image
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    quality={quality}
                    sizes={sizes}
                    priority={priority || aboveFold}
                    className={imgClassName}
                    onLoad={onLoadHandler}
                />
            ) : null}
            {(!loaded && skeleton) || busy ? (
                <div className={cx('skeleton-loader')}>
                    <Skeleton
                        bones={[{ width: '100%', height: '100%' }]}
                        theme={skeletonTheme}
                        transparent={skeletonTransparent}
                        animate={animateSkeleton}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default React.memo(SmartImage);
