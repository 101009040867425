import { ReactNode } from 'react';

interface Props {
    forwardRef?: any;
    id: string;
    name: string;
    method?: 'POST' | 'GET';
    className?: string;
    onSubmitHandler?: (event: React.FormEvent<HTMLFormElement>) => void;
    children: ReactNode;
}

const FormWrapper: React.FC<Props> = ({
    forwardRef,
    id,
    name,
    method = 'GET',
    className,
    onSubmitHandler,
    children,
}) => {
    return (
        <form
            ref={forwardRef}
            id={id}
            name={name}
            method={method}
            onSubmit={onSubmitHandler}
            className={className}>
            {children}
        </form>
    );
};

export default FormWrapper;
