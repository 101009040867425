import { createSlice } from '@reduxjs/toolkit';

import { ToastType } from '@/components/indicators/toast/Toast';

interface CalendarShareData {
    id?: string | null;
    title?: string | null;
    location?: string | null;
    description?: string | null;
    date?: string | null;
}

interface ImageModalData {
    id?: string | number | null;
    src: string;
    title?: string;
    caption?: string;
    images: ResultImage[];
}

interface GenericModalData {
    title: string;
    body: string;
    hasMarkdownContent?: boolean;
}

interface ToastData {
    type: ToastType;
    dismissTimer: number;
    message: string;
}

export interface AppState {
    appReady: boolean;
    backgroundImage: string;
    menuOpen: boolean;
    mobile: boolean;
    tablet: boolean;
    tabletPortrait: boolean;
    calculatingViewport: boolean;
    geoLocating: boolean;
    calendarShareOpen: boolean;
    calendarShareData: CalendarShareData;
    imageModalOpen: boolean;
    imageModalData: ImageModalData;
    genericModalOpen: boolean;
    genericModalData: GenericModalData;
    toastOpen: boolean;
    toastData: ToastData;
    shareOpen: boolean;
    shareUrl?: string | null;
    shareSource?: ListItemType | null;
    shareTerm?: string | null;
    searchOpen: boolean;
    signInOpen: boolean;
    signUpOpen: boolean;
    verifyEmailOpen: boolean;
    _lastUpdated?: number | null;
}

const initialState: AppState = {
    appReady: false,
    backgroundImage: '',
    menuOpen: false,
    mobile: false,
    tablet: false,
    tabletPortrait: false,
    calculatingViewport: false,
    geoLocating: false,
    calendarShareOpen: false,
    calendarShareData: {
        id: null,
        title: null,
        location: null,
        description: null,
        date: null,
    },
    imageModalOpen: false,
    imageModalData: {
        id: null,
        title: '',
        src: '',
        caption: undefined,
        images: [],
    },
    genericModalOpen: false,
    genericModalData: {
        title: '',
        body: '',
        hasMarkdownContent: false,
    },
    toastOpen: false,
    toastData: {
        type: ToastType.Plain,
        dismissTimer: 5,
        message: '',
    },
    shareOpen: false,
    shareUrl: null,
    shareSource: null,
    shareTerm: null,
    searchOpen: false,
    signInOpen: false,
    signUpOpen: false,
    verifyEmailOpen: false,
    _lastUpdated: null,
};

const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppReadyState: (state, action) => {
            state.appReady = action.payload;
            state._lastUpdated = Date.now();
        },
        setBackgroundImage: (state, action) => {
            state.backgroundImage = action.payload;
            state._lastUpdated = Date.now();
        },
        setMenuOpen: (state, action) => {
            state.menuOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setGeoLocating: (state, action) => {
            state.geoLocating = action.payload;
            state._lastUpdated = Date.now();
        },
        setCalendarShareOpen: (state, action) => {
            state.calendarShareOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setCalendarShareData: (state, action) => {
            state.calendarShareData = action.payload;
            state._lastUpdated = Date.now();
        },
        setImageModalOpen: (state, action) => {
            state.imageModalOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setImageModalData: (state, action) => {
            state.imageModalData = action.payload;
            state._lastUpdated = Date.now();
        },
        setGenericModalOpen: (state, action) => {
            state.genericModalOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setGenericModalData: (state, action) => {
            state.genericModalData = action.payload;
            state._lastUpdated = Date.now();
        },
        setShareOpen: (state, action) => {
            state.shareOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setShareUrl: (state, action) => {
            state.shareUrl = action.payload;
            state._lastUpdated = Date.now();
        },
        setShareSource: (state, action) => {
            state.shareSource = action.payload;
            state._lastUpdated = Date.now();
        },
        setShareTerm: (state, action) => {
            state.shareTerm = action.payload;
            state._lastUpdated = Date.now();
        },
        setSearchOpen: (state, action) => {
            state.searchOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setSignInOpen: (state, action) => {
            state.signInOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setSignUpOpen: (state, action) => {
            state.signUpOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setToastData: (state, action) => {
            state.toastData = action.payload;
            state._lastUpdated = Date.now();
        },
        setToastOpen: (state, action) => {
            state.toastOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setVerifyEmailOpen: (state, action) => {
            state.verifyEmailOpen = action.payload;
            state._lastUpdated = Date.now();
        },
        setIsMobile: (state, action) => {
            state.mobile = action.payload;
            state._lastUpdated = Date.now();
        },
        setIsTablet: (state, action) => {
            state.tablet = action.payload;
            state._lastUpdated = Date.now();
        },
        setIsTabletPortrait: (state, action) => {
            state.tabletPortrait = action.payload;
            state._lastUpdated = Date.now();
        },
        setCalculatingViewport: (state, action) => {
            state.calculatingViewport = action.payload;
            state._lastUpdated = Date.now();
        },
    },
});

export const selectApp = (state) => state.app;
export const {
    setAppReadyState,
    setBackgroundImage,
    setMenuOpen,
    setGeoLocating,
    setCalendarShareOpen,
    setCalendarShareData,
    setImageModalOpen,
    setImageModalData,
    setGenericModalOpen,
    setGenericModalData,
    setShareOpen,
    setShareUrl,
    setShareSource,
    setShareTerm,
    setSearchOpen,
    setSignInOpen,
    setSignUpOpen,
    setToastData,
    setToastOpen,
    setVerifyEmailOpen,
    setIsMobile,
    setIsTablet,
    setIsTabletPortrait,
    setCalculatingViewport,
} = app.actions;

export default app.reducer;
