import classNames from 'classnames/bind';

import ActiveLink from '@/components/active-link/ActiveLink';
import Icon, { IconType } from '@/components/icon/Icon';

import styles from '@/components/form/dropdown/styles.module.scss';

const cx = classNames.bind(styles);

const FavoritesMenu: React.FC = () => {
    return (
        <div className={cx('favorites-menu')}>
            <ul className={cx('__menu-section')}>
                <li>
                    <ActiveLink
                        href='/user/favorites/artists'
                        className={cx('nav__item')}
                        activeClassName={cx('nav__item--active')}>
                        <span className={cx('nav__item-icon')}>
                            <Icon name={IconType.Artist} />
                        </span>
                        <span>Artists</span>
                    </ActiveLink>
                </li>
                <li>
                    <ActiveLink
                        href='/user/favorites/festivals'
                        className={cx('nav__item')}
                        activeClassName={cx('nav__item--active')}>
                        <span className={cx('nav__item-icon')}>
                            <Icon name={IconType.PartyPopper} />
                        </span>
                        <span>Festivals</span>
                    </ActiveLink>
                </li>
            </ul>
        </div>
    );
};

export default FavoritesMenu;
