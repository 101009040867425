import classNames from 'classnames/bind';

import Icon, { IconType, IconSize } from '@/components/icon/Icon';
import Spinner from '@/components/loading/spinner/Spinner';
import Link from 'next/link';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    children?: JSX.Element;
    label?: string;
    ariaLabel?: string;
    theme?: ElementTheme;
    customClassName?: string;
    type?: 'button' | 'submit';
    textAlign?: 'left' | 'center' | 'right';
    size?: 'extra-small' | 'small' | 'default' | 'large';
    paddingHorizonal?: boolean;
    href?: string;
    as?: string;
    target?: '_blank' | '_self' | '_top';
    onPress?: () => void;
    disabled?: boolean;
    busy?: boolean;
    wrap?: boolean;
    scroll?: boolean;
    leftIcon?: IconType;
    rightIcon?: IconType;
    iconSize?: IconSize;
    circle?: boolean;
    transparent?: boolean;
    link?: boolean;
    underline?: boolean;
    darkMode?: boolean;
}

export default function Button({
    children,
    label,
    ariaLabel,
    customClassName = '',
    theme = 'outline',
    type = 'button',
    textAlign = 'center',
    paddingHorizonal = true,
    size = 'default',
    href,
    as,
    target,
    onPress,
    disabled,
    busy,
    wrap = true,
    scroll = true,
    leftIcon,
    rightIcon,
    iconSize = IconSize.Small,
    circle,
    transparent,
    link = false,
    underline = false,
    darkMode = false,
}: Props) {
    const Label = () =>
        !customClassName ? (
            <span
                className={cx('button__label', `button__label--${textAlign}`)}>
                {label}
            </span>
        ) : (
            <span className={customClassName}>{label}</span>
        );

    const classNames = cx(
        'button',
        `button--${theme}`,
        `button--${size}`,
        {
            'button--disabled': disabled || busy,
        },
        {
            'button--busy': busy,
        },
        { 'button--full-width': !wrap },
        { 'button--has-left-icon': leftIcon },
        { 'button--has-right-icon': rightIcon },
        { 'button--has-padding-horizontal': paddingHorizonal },
        { 'button--has-no-padding': !paddingHorizonal },
        { 'button--has-no-children': !children && !label },
        { 'button--circle': circle },
        iconSize ? `button--icon-size-${iconSize}` : '',
        { 'button--nobg-noborder': transparent },
        { 'button--link': link },
        { 'button--underline': underline },
        { 'button--dark-mode': darkMode }
    );

    const LeftIcon = () => (
        <span className={cx('icon__container')}>
            {leftIcon ? <Icon name={leftIcon} size={iconSize} /> : null}
        </span>
    );
    const RightIcon = () => (
        <span className={cx('icon__container')}>
            {rightIcon ? <Icon name={rightIcon} size={iconSize} /> : null}
        </span>
    );

    const PlaceholderIcon = () => (
        <span className={cx('icon__placeholder')}></span>
    );

    if (href) {
        return (
            <Link
                href={href}
                as={as}
                target={target}
                className={[classNames, customClassName].join(' ')}
                scroll={scroll}
                aria-label={ariaLabel}
                rel='noopener'
                onClick={onPress}>
                {leftIcon ? <LeftIcon /> : null}
                {rightIcon && !leftIcon && !wrap ? <PlaceholderIcon /> : null}
                {label ? <Label /> : null}
                {children ? children : null}
                {rightIcon ? <RightIcon /> : null}
                {leftIcon && !rightIcon && !wrap ? <PlaceholderIcon /> : null}
                {busy ? <Spinner type={theme} /> : null}
            </Link>
        );
    } else {
        return (
            <button
                onClick={onPress}
                type={type}
                className={[classNames, customClassName].join(' ')}
                disabled={disabled || busy}
                aria-label={ariaLabel}>
                {leftIcon ? <LeftIcon /> : null}
                {rightIcon && !leftIcon && !wrap ? <PlaceholderIcon /> : null}
                {label ? <Label /> : null}
                {children ? children : null}
                {rightIcon ? <RightIcon /> : null}
                {leftIcon && !rightIcon && !wrap ? <PlaceholderIcon /> : null}
                {busy ? <Spinner type={theme} /> : null}
            </button>
        );
    }
}
