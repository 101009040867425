import classNames from 'classnames/bind';

import IconButton from '@/components/icon/IconButton';
import { IconType } from '@/components/icon/Icon';
import { setSearchOpen } from '@/store/app';
import { useAppDispatch } from '@/store/hooks';
import { Events, trackEvent } from '@/utils/analytics';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const SearchButton = () => {
    const dispatch = useAppDispatch();

    const showSearch = () => {
        trackEvent(Events.ClickedSearch, { path: window.location.pathname });
        dispatch(setSearchOpen(true));
    };

    return (
        <IconButton
            type={IconType.Search}
            ariaLabel='Search'
            onPress={showSearch}
            size='lg'
            theme='outline-primary'
            transparent
        />
    );
};

export default SearchButton;
