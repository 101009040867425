import { PropsWithChildren, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';

interface Props extends LinkProps {
    className?: string;
    activeClassName: string;
}

const ActiveLink: React.FC<PropsWithChildren<Props>> = ({
    className,
    activeClassName,
    children,
    ...props
}) => {
    const { asPath, isReady } = useRouter();
    const [computedClassName, setComputedClassName] = useState(className);

    useEffect(() => {
        if (isReady) {
            const linkPathname = new URL(
                (props.as || props.href) as string,
                location.href
            ).pathname;

            const activePathname = new URL(asPath, location.href).pathname;
            const index = activePathname.indexOf(linkPathname);
            const newClassName =
                index === 0
                    ? `${className} ${activeClassName}`.trim()
                    : className;
            if (newClassName !== computedClassName)
                setComputedClassName(newClassName);
        }
    }, [
        asPath,
        isReady,
        props.as,
        props.href,
        activeClassName,
        className,
        computedClassName,
    ]);

    return (
        <Link className={computedClassName} {...props}>
            {children}
        </Link>
    );
};

export default ActiveLink;
