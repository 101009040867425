import $http from '@/utils/http';
import { AppStore } from '@/store/index';
import { parseError } from '@/utils/apiErrorHandler';
import { setGeoLocating, setToastData, setToastOpen } from '@/store/app';
import {
    setCity,
    setContinent,
    setCountry,
    setCountryId,
    setCurrentLocation,
    setRegion,
} from '@/store/user';

const getCoordinates = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
        });
    });
};

type LocationResult = Pick<
    User,
    'city' | 'country' | 'countryId' | 'currentLocation' | 'region'
>;

export const getLocation = async (
    authenticated: boolean,
    dispatch: AppStore['dispatch']
): Promise<LocationResult | undefined> => {
    try {
        dispatch(setGeoLocating(true));

        const position = await getCoordinates();
        const { coords } = position;
        let userLocation = {
            currentLocation: undefined,
            city: undefined,
            country: undefined,
            countryId: undefined,
            continent: undefined,
            region: undefined,
        };

        if (authenticated) {
            userLocation = await $http.put({
                endpoint: '/account/update-location',
                params: {
                    currentLocation: {
                        coords: {
                            latitude: coords.latitude,
                            longitude: coords.longitude,
                        },
                    },
                },
            });
        } else {
            userLocation = await $http.get({
                endpoint: '/user/get-location',
                params: {
                    currentLocation: {
                        coords: {
                            latitude: coords.latitude,
                            longitude: coords.longitude,
                        },
                    },
                },
            });
        }

        dispatch(setCurrentLocation(userLocation.currentLocation));
        dispatch(setContinent(userLocation.continent));
        dispatch(setCity(userLocation.city));
        dispatch(setCountry(userLocation.country));
        dispatch(setCountryId(userLocation.countryId));
        dispatch(setRegion(userLocation.region));

        dispatch(setGeoLocating(false));

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                dismissTimer: 5,
                message: 'Saved current location.',
            })
        );

        return userLocation;
    } catch (error) {
        dispatch(setGeoLocating(false));
        parseError({ error, throwError: false });
    }
};

export const removeLocation = async (
    authenticated: boolean,
    dispatch: AppStore['dispatch']
) => {
    try {
        dispatch(setGeoLocating(true));

        if (authenticated) {
            await $http.put({
                endpoint: '/account/update-location',
                params: {
                    currentLocation: {},
                },
            });
        }

        dispatch(setCurrentLocation(null));
        dispatch(setCity(null));
        dispatch(setContinent(null));
        dispatch(setCountry(null));
        dispatch(setCountryId(null));
        dispatch(setRegion(null));

        dispatch(setGeoLocating(false));

        dispatch(setToastOpen(true));
        dispatch(
            setToastData({
                type: 'success',
                dismissTimer: 5,
                message: 'Removed current location.',
            })
        );
    } catch (error) {
        dispatch(setGeoLocating(false));
        parseError({ error });
    }
};
