import { createSlice } from '@reduxjs/toolkit';

export interface GenreState {
    activeGenres?: Genre[];
    featuredGenres?: Genre[];
    userGenres?: Genre[];
    maxUserGenres?: number;
    _lastUpdated?: number | null;
}

const initialState: GenreState = {
    activeGenres: undefined,
    featuredGenres: undefined,
    userGenres: undefined,
    maxUserGenres: undefined,
    _lastUpdated: null,
};

const genre = createSlice({
    name: 'genre',
    initialState,
    reducers: {
        setFeaturedGenres: (state, action) => {
            state.featuredGenres = action.payload;
        },
        setActiveGenres: (state, action) => {
            state.activeGenres = action.payload;
        },
        setUserGenres: (state, action) => {
            state.userGenres = action.payload;
        },
        setMaxUserGenres: (state, action) => {
            state.maxUserGenres = action.payload;
        },
    },
});

export const selectGenre = (state) => state.genre;
export const {
    setActiveGenres,
    setFeaturedGenres,
    setMaxUserGenres,
    setUserGenres,
} = genre.actions;

export default genre.reducer;
