import classNames from 'classnames/bind';
import { Icon, addCollection } from '@iconify-icon/react';
import AccountDetails from '@iconify-icons/mdi/account-details';
import Apple from '@iconify-icons/simple-icons/apple';
import Artist from '@iconify-icons/mdi/artist';
import AwardStarRounded from '@iconify-icons/material-symbols/award-star-rounded';
import Bell from '@iconify-icons/mdi/bell';
import BookmarkMusic from '@iconify-icons/mdi/bookmark-music';
import Building from '@iconify-icons/mdi/building';
import CheckMarkRounded from '@iconify-icons/material-symbols/check-small-rounded';
import ChevronDown from '@iconify-icons/mdi/chevron-down';
import ChevronLeft from '@iconify-icons/mdi/chevron-left';
import ChevronRight from '@iconify-icons/mdi/chevron-right';
import ChevronUp from '@iconify-icons/mdi/chevron-up';
import Close from '@iconify-icons/mdi/close';
import CommentQuote from '@iconify-icons/mdi/comment-quote';
import Compare from '@iconify-icons/iconamoon/compare-fill';
import CompareOutline from '@iconify-icons/iconamoon/compare';
import Curate from '@iconify-icons/mdi/vector-combine';
import DotsY from '@iconify-icons/mdi/dots-vertical';
import Deezer from '@iconify-icons/simple-icons/deezer';
import Directions from '@iconify-icons/material-symbols/directions';
import Download from '@iconify-icons/mdi/download';
import Email from '@iconify-icons/mdi/email';
import EmailNewsletter from '@iconify-icons/mdi/email-newsletter';
import Eye from '@iconify-icons/mdi/eye';
import EyeOff from '@iconify-icons/mdi/eye-off';
import Facebook from '@iconify-icons/mdi/facebook';
import Favorite from '@iconify-icons/material-symbols/favorite-rounded';
import FavoriteOutline from '@iconify-icons/material-symbols/favorite-outline-rounded';
import Flight from '@iconify-icons/material-symbols/flight';
import Google from '@iconify-icons/mdi/google';
import GoogleCalendar from '@iconify-icons/simple-icons/googlecalendar';
import HelpCircle from '@iconify-icons/mdi/help-circle';
import Home from '@iconify-icons/material-symbols/home';
import Hotel from '@iconify-icons/material-symbols/hotel';
import Instagram from '@iconify-icons/simple-icons/instagram';
import Link from '@iconify-icons/material-symbols/link';
import LinkedIn from '@iconify-icons/simple-icons/linkedin';
import Location from '@iconify-icons/mdi/location';
import LocationOff from '@iconify-icons/mdi/location-off';
import Logout from '@iconify-icons/mdi/logout';
import MapMarker from '@iconify-icons/mdi/map-marker';
import MicrosoftOutlook from '@iconify-icons/simple-icons/microsoftoutlook';
import MicrosoftOffice from '@iconify-icons/simple-icons/microsoftoffice';
import Menu from '@iconify-icons/mdi/menu';
import MenuSwap from '@iconify-icons/mdi/menu-swap';
import NearMe from '@iconify-icons/mdi/near-me';
import NewstandRounded from '@iconify-icons/material-symbols/newsstand-rounded';
import PartyPopper from '@iconify-icons/mdi/party-popper';
import Reload from '@iconify-icons/mdi/reload';
import Search from '@iconify-icons/feather/search';
import Settings from '@iconify-icons/mdi/settings';
import Sort from '@iconify-icons/material-symbols/sort';
import Share from '@iconify-icons/material-symbols/share';
import ShareVariant from '@iconify-icons/mdi/share-variant';
import Shield from '@iconify-icons/mdi/shield';
import Speaker from '@iconify-icons/mdi/speaker';
import SpeakerGroup from '@iconify-icons/material-symbols/speaker-group';
import Spotify from '@iconify-icons/mdi/spotify';
import Star from '@iconify-icons/mdi/star';
import StarOutline from '@iconify-icons/mdi/star-outline';
import Sync from '@iconify-icons/material-symbols/sync-alt-rounded';
import Ticket from '@iconify-icons/mdi/ticket';
import Twitter from '@iconify-icons/simple-icons/x';
import User from '@iconify-icons/mdi/user';
import WhatsApp from '@iconify-icons/simple-icons/whatsapp';
import X from '@iconify-icons/simple-icons/x';
import Yahoo from '@iconify-icons/simple-icons/yahoo';
import YouTube from '@iconify-icons/mdi/youtube';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

addCollection({
    prefix: 'feather',
    icons: {
        search: Search,
    },
});

addCollection({
    prefix: 'simple-icons',
    icons: {
        apple: Apple,
        deezer: Deezer,
        facebook: Facebook,
        googlecalendar: GoogleCalendar,
        instagram: Instagram,
        linkedin: LinkedIn,
        microsoftoutlook: MicrosoftOutlook,
        microsoftoffice: MicrosoftOffice,
        twitter: Twitter,
        x: X,
        whatsapp: WhatsApp,
        yahoo: Yahoo,
        youtube: YouTube,
    },
});

addCollection({
    prefix: 'ei',
    icons: {
        linkedin: LinkedIn,
        twitter: Twitter,
    },
});

addCollection({
    prefix: 'material-symbols',
    icons: {
        award: AwardStarRounded,
        'checkmark-rounded': CheckMarkRounded,
        directions: Directions,
        favorite: Favorite,
        'favorite-outline': FavoriteOutline,
        flight: Flight,
        hotel: Hotel,
        home: Home,
        link: Link,
        'newstand-rounded': NewstandRounded,
        share: Share,
        'speaker-group': SpeakerGroup,
        sort: Sort,
        sync: Sync,
    },
});

addCollection({
    prefix: 'iconamoon',
    icons: {
        compare: CompareOutline,
        'compare-fill': Compare,
    },
});

addCollection({
    prefix: 'mdi',
    icons: {
        'account-details': AccountDetails,
        artist: Artist,
        bell: Bell,
        'bookmark-music': BookmarkMusic,
        building: Building,
        chevron_down: ChevronDown,
        chevron_left: ChevronLeft,
        chevron_right: ChevronRight,
        chevron_up: ChevronUp,
        close: Close,
        'comment-quote': CommentQuote,
        curate: Curate,
        dotsy: DotsY,
        download: Download,
        email: Email,
        'email-newsletter': EmailNewsletter,
        eye: Eye,
        'eye-off': EyeOff,
        'help-circle': HelpCircle,
        google: Google,
        logout: Logout,
        location: Location,
        'location-off': LocationOff,
        'map-marker': MapMarker,
        menu: Menu,
        'menu-swap': MenuSwap,
        'near-me': NearMe,
        'party-popper': PartyPopper,
        reload: Reload,
        shield: Shield,
        settings: Settings,
        'share-variant': ShareVariant,
        speaker: Speaker,
        spotify: Spotify,
        star: Star,
        'star-outline': StarOutline,
        ticket: Ticket,
        user: User,
    },
});

export enum IconType {
    AccountDetails = 'mdi:account-details',
    Apple = 'simple-icons:apple',
    Artist = 'mdi:artist',
    Award = 'material-symbols:award-star-rounded',
    Bell = 'mdi:bell',
    Building = 'mdi:building',
    BookmarkMusic = 'mdi:bookmark-music',
    CheckmarkRounded = 'material-symbols:check-small-rounded',
    ChevronDown = 'mdi:chevron-down',
    ChevronLeft = 'mdi:chevron-left',
    ChevronRight = 'mdi:chevron-right',
    ChevronUp = 'mdi:chevron-up',
    Compare = 'iconamoon:compare-fill',
    CompareOutline = 'iconamoon:compare',
    CommentQuote = 'mdi:comment-quote',
    Close = 'mdi:close',
    Curate = 'mdi:vector-combine',
    Deezer = 'simple-icons:deezer',
    Directions = 'material-symbols:directions',
    DotsX = 'mdi:dots-horizontal',
    DotsY = 'mdi:dots-vertical',
    Download = 'mdi:download',
    Email = 'mdi:email',
    EmailNewsletter = 'mdi:email-newsletter',
    Eye = 'mdi:eye',
    EyeOff = 'mdi:eye-off',
    Facebook = 'simple-icons:facebook',
    Favorite = 'material-symbols:favorite',
    FavoriteOutline = 'material-symbols:favorite-outline',
    Flight = 'material-symbols:flight',
    Google = 'mdi:google',
    GoogleCalendar = 'simple-icons:googlecalendar',
    HelpCircle = 'mdi:help-circle',
    Hotel = 'material-symbols:hotel',
    Home = 'material-symbols:home',
    Instagram = 'simple-icons:instagram',
    LinkedIn = 'simple-icons:linkedin',
    Link = 'material-symbols:link',
    Location = 'mdi:location',
    LocationOff = 'mdi:location-off',
    Logout = 'mdi:logout',
    MapMarker = 'mdi:map-marker',
    Menu = 'mdi:menu',
    MenuSwap = 'mdi:menu-swap',
    MicrosoftOffice = 'simple-icons:microsoftoffice',
    MicrosoftOutlook = 'simple-icons:microsoftoutlook',
    NearMe = 'mdi:near-me',
    NewstandRounded = 'material-symbols:newstand-rounded',
    PartyPopper = 'mdi:party-popper',
    Reload = 'mdi:reload',
    Search = 'feather:search',
    Settings = 'mdi:settings',
    Shield = 'mdi:shield',
    Sort = 'material-symbols:sort',
    Share = 'material-symbols:share',
    ShareVariant = 'mdi:share-variant',
    Speaker = 'mdi:speaker',
    SpeakerGroup = 'material-symbols:speaker-group',
    Spotify = 'mdi:spotify',
    Star = 'mdi:star',
    StarOutline = 'mdi:star-outline',
    Sync = 'material-symbols:sync-alt-rounded',
    Ticket = 'mdi:ticket',
    Twitter = 'simple-icons:twitter',
    User = 'mdi:user',
    WhatsApp = 'simple-icons:whatsapp',
    X = 'simple-icons:x',
    Yahoo = 'simple-icons:yahoo',
    YouTube = 'simple-icons:youtube',
}

export enum IconSize {
    Tiny = 'xs',
    Small = 'sm',
    Medium = 'base',
    Large = 'lg',
    XLarge = 'xl',
    XXLarge = 'xxl',
}
interface Props {
    name: IconType;
    size?: IconSize;
}

export default function CustomIcon({ name, size = IconSize.Small }: Props) {
    const getSize = () => {
        switch (size) {
            case IconSize.Tiny:
                return 16;
            case IconSize.Small:
                return 20;
            case IconSize.Medium:
                return 24;
            case IconSize.Large:
                return 32;
            case IconSize.XLarge:
                return 40;
            case IconSize.XXLarge:
                return 72;
        }
    };

    return (
        <span className={cx('icon__container')}>
            <Icon icon={name} width={getSize()} inline />
        </span>
    );
}
