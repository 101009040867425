import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';

import IconButton from '@/components/icon/IconButton';
import styles from './styles.module.scss';
import { IAnimate, animate } from '@/utils/helper';
import { IconSize, IconType } from '@/components/icon/Icon';

const cx = classNames.bind(styles);

export enum ToastType {
    Plain = 'plain',
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
}

interface Props {
    message: string;
    type: ToastType;
    dismissTimer?: number;
    onDismiss: () => void;
}

const Toast: React.FC<Props> = ({
    message,
    type = ToastType.Plain,
    dismissTimer = 5,
    onDismiss,
}) => {
    const router = useRouter();

    const elem = useRef<HTMLOutputElement>(null);
    const timer = useRef<IAnimate>();
    const animationDuration = 300;
    const [barScaleX, setBarScaleX] = useState(100);
    const [mounted, setMountedState] = useState(false);

    const onDismissHandler = useCallback(() => {
        setMountedState(false);

        if (timer?.current) {
            timer.current.stop();
            timer.current.start(animationDuration, (elapsedTime) => {
                const frame = Math.min(elapsedTime, animationDuration);

                if (frame === animationDuration) {
                    timer?.current?.stop();
                    onDismiss();
                }
            });
        }
    }, [onDismiss]);

    const onDismissTransitionHandler = useCallback(() => {
        if (typeof window !== 'undefined') {
            const seconds = dismissTimer * 1000;
            const maxFrames = seconds / 100;

            if (timer?.current) {
                timer.current.start(seconds, (elapsedTime: number) => {
                    const frame = Math.min(elapsedTime / 100, maxFrames);

                    setBarScaleX(1 - frame / maxFrames);

                    if (frame === maxFrames) onDismissHandler();
                });
            }
        }
    }, [dismissTimer, onDismissHandler]);

    useEffect(() => {
        const handleRouteChange = () => onDismiss();

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events, onDismiss]);

    useEffect(() => {
        if (!timer.current) {
            timer.current = animate();

            timer.current.start(100, (elapsedTime) => {
                const frame = Math.min(elapsedTime, 100);

                if (frame === 100) {
                    timer?.current?.stop();
                    setMountedState(true);

                    if (dismissTimer) {
                        timer?.current?.start(
                            animationDuration,
                            (elapsedTime) => {
                                const frame = Math.min(
                                    elapsedTime,
                                    animationDuration
                                );

                                if (frame === animationDuration) {
                                    timer?.current?.stop();
                                    onDismissTransitionHandler();
                                }
                            }
                        );
                    }
                }
            });
        }

        return () => {
            if (typeof window !== 'undefined') {
                if (timer.current) {
                    timer.current.stop();
                    timer.current = undefined;
                }
            }
        };
    }, [onDismissHandler, onDismissTransitionHandler, dismissTimer]);

    return (
        <output
            ref={elem}
            role='status'
            className={cx('toast', { '--mounted': mounted }, `--${type}`)}>
            <div className={cx('__content')}>
                <p className={cx('__message')}>{message}</p>
                <IconButton
                    ariaLabel='Dismiss toast'
                    type={IconType.Close}
                    iconSize={IconSize.Medium}
                    theme='outline-black'
                    onPress={onDismissHandler}
                />
            </div>
            {dismissTimer ? (
                <div
                    className={cx('__timer-bar')}
                    style={{ transform: `scaleX(${barScaleX})` }}></div>
            ) : null}
        </output>
    );
};

export default Toast;
