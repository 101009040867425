import router from 'next/router';

import {
    setCity,
    setCountry,
    setCountryId,
    setCurrentLocation,
    setRegion,
    setUserDetails,
    setUserArtists,
    setUserFestivals,
} from '@/store/user';
import { setAuthenticatedState } from '@/store/auth';
import { setVerifyEmailOpen } from '@/store/app';
import { AppStore } from '../store';

export const goHome = () => {
    window.location.href = '/';
};

interface Props {
    onRefresh?: () => void;
    reloadPage?: boolean;
}

export const gatedRoutes = [
    'user/settings',
    'user/curate',
    'user/crate',
    'user/feedback',
    'user/favorites',
    'user/compare',
    'user/recommended',
    'user/notifications',
];

export const refreshPage = (props?: Props) => {
    if (props?.reloadPage) {
        window.location.reload();
        return;
    }

    const onRouteCompleteHandler = () => {
        if (props?.onRefresh) {
            props?.onRefresh();
            router.events.off('routeChangeComplete', onRouteCompleteHandler);
        }
    };

    router.events.on('routeChangeComplete', onRouteCompleteHandler);

    router.replace(router.asPath, undefined, {
        scroll: false,
    });
};

export const goSignIn = () => {
    router.push('/auth/sign-in');
};

export const goSignUp = () => {
    router.push('/auth/sign-up');
};

interface Router {
    asPath?: string;
    pathname?: string;
    query: Record<string, any>;
}

interface SetAuthStateArgs {
    store: AppStore;
    user?: User;
    router: Router;
    baseUrl: string;
}

export const setAuthState = ({
    store,
    user,
    router,
    baseUrl,
}: SetAuthStateArgs) => {
    const activePathname = router.asPath
        ? new URL(router.asPath, baseUrl).pathname
        : undefined;

    if (user) {
        store.dispatch(setAuthenticatedState(true));
        store.dispatch(setCity(user.city));
        store.dispatch(setCountry(user.country));
        store.dispatch(setCountryId(user.countryId));
        store.dispatch(setCurrentLocation(user.currentLocation));
        store.dispatch(setRegion(user.region));
        store.dispatch(setUserDetails(user));

        if (
            !user.confirmed &&
            activePathname !== '/auth/verify-email' &&
            activePathname !== '/auth/sign-out' &&
            activePathname !== '/user/settings/manage-account/delete'
        ) {
            store.dispatch(setVerifyEmailOpen(true));
        } else {
            store.dispatch(setVerifyEmailOpen(false));
        }
    } else {
        store.dispatch(setAuthenticatedState(false));
        store.dispatch(setUserDetails({}));
        store.dispatch(setUserArtists([]));
        store.dispatch(setUserFestivals([]));
    }
};
