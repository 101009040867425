import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
    authenticated: boolean;
    _lastUpdated?: number | null;
}

const initialState: AuthState = {
    authenticated: false,
    _lastUpdated: null,
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticatedState: (state, action) => {
            state.authenticated = action.payload;
            state._lastUpdated = Date.now();
        },
    },
});

export const selectAuth = (state) => state.auth;
export const { setAuthenticatedState } = auth.actions;

export default auth.reducer;
