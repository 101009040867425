import classNames from 'classnames/bind';

import Icon, { IconType, IconSize } from '@/components/icon/Icon';
import { setMenuOpen } from '@/store/app';
import { useAppDispatch } from '@/store/hooks';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const MenuButton = () => {
    const dispatch = useAppDispatch();

    const showMenu = () => {
        dispatch(setMenuOpen(true));
    };

    return (
        <button
            type='button'
            className={cx('mobile-button')}
            aria-label='Menu'
            onClick={showMenu}>
            <Icon name={IconType.Menu} size={IconSize.Medium} />
        </button>
    );
};

export default MenuButton;
