import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export interface Bone {
    customClass?: string;
    width?: number | string;
    height?: number | string;
    rounded?: boolean;
}

interface Props {
    bones: Bone[];
    theme?: 'dark' | 'light';
    transparent?: boolean;
    circle?: boolean;
    animate?: boolean;
}

const Skeleton: React.FC<Props> = ({
    bones,
    theme = 'dark',
    transparent = false,
    circle,
    animate = true,
}) => {
    const getUid = () => Date.now();

    return bones?.map((b, idx) => (
        <div
            key={`__bone-${getUid()}-${idx}`}
            className={cx('bone', b.customClass)}
            style={{ width: b.width, height: b.height }}>
            <div
                className={cx('__container', `--${theme}`, {
                    '--rounded': b?.rounded ?? true,
                    '--circle': circle,
                    '--transparent': transparent,
                })}>
                {animate ? <div className={cx('__animation')}></div> : null}
            </div>
        </div>
    ));
};

export default Skeleton;
