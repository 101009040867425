import type { NextApiResponse } from 'next';
import { AxiosError } from 'axios';

interface ErrorHandler {
    statusCode: number;
    onError: () => void;
}

interface ParseErrorArgs {
    error: AxiosError;
    res?: NextApiResponse;
    throwError?: boolean;
    onError?: ErrorHandler[];
    trackError?: boolean;
}

interface ServerError {
    id: string;
    message: string;
}

interface StrapiErrorMessage {
    messages: ServerError[];
}

export const parseError = async ({
    error,
    res,
    onError,
    trackError = false,
    throwError = true,
}: ParseErrorArgs) => {
    if (trackError) {
        const Sentry = await import('@sentry/react');
        Sentry.captureException(error);
    }

    if (error?.response) {
        const response = error.response;
        const { status, statusText } = response;

        if (res) {
            res.status(status).send(statusText);
        }

        if (onError) {
            for (const errorHandler of onError)
                if (status === errorHandler.statusCode) errorHandler.onError();
        }
    } else {
        // most likely server is unavailable
        if (res) res.status(503).end();
    }

    if (throwError) throw error;
};
