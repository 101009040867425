import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';

import appReducer from './app';
import authReducer from './auth';
import genreReducer from './genre';
import searchReducer from './search';
import userReducer from './user';

const combinedReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    genre: genreReducer,
    search: searchReducer,
    user: userReducer,
});

const rootReducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // previous state
            ...action.payload, // initial state
        };

        for (let key in state) {
            if (state[key]._lastUpdated) {
                if (
                    !action.payload[key]._lastUpdated ||
                    state[key]._lastUpdated > action.payload[key]._lastUpdated
                ) {
                    nextState[key] = state[key];
                }
            } else if (action.payload[key]._lastUpdated) {
                if (
                    !state[key]._lastUpdated ||
                    action.payload[key]._lastUpdated > state[key]._lastUpdated
                ) {
                    nextState[key] = action.payload[key];
                }
            }
        }

        return nextState;
    } else {
        return combinedReducer(state, action);
    }
};

export const makeStore = () =>
    configureStore({
        reducer: rootReducer,
        devTools: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
    });

export const wrapper = createWrapper(makeStore, {
    debug: false,
});

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
