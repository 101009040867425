// TODO: Create component for skeleton loading
import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export enum SpinnerSize {
    Tiny = 'tiny',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

interface Props {
    type?: ElementTheme;
    size?: SpinnerSize;
    fitContainer?: boolean;
    align?: 'left' | 'center' | 'right';
}

export default function Spinner({
    type = 'outline',
    size = SpinnerSize.Small,
    fitContainer = true,
    align = 'center',
}: Props) {
    return (
        <div
            className={cx(
                'spinner',
                `spinner--${type}`,
                `spinner--${size}`,
                `spinner--align-${align}`,
                { 'spinner--fit-container': fitContainer }
            )}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'>
                <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'></circle>
                <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
            </svg>
        </div>
    );
}
