import classNames from 'classnames/bind';
import Markdown from 'react-markdown';

import styles from './styles.module.scss';
import Head from 'next/head';

const cx = classNames.bind(styles);

interface Props {
    text: string;
}

const PostText: React.FC<Props> = ({ text }) => {
    const Heading = ({ children }) => (
        <p className={cx('__heading')}>{children}</p>
    );

    return (
        <div className={cx('post__text')}>
            <Markdown
                components={{
                    h1: (props) => <Heading>{props.children}</Heading>,
                    h2: (props) => <Heading>{props.children}</Heading>,
                    h3: (props) => <Heading>{props.children}</Heading>,
                    h4: (props) => <Heading>{props.children}</Heading>,
                    h5: (props) => <Heading>{props.children}</Heading>,
                    h6: (props) => <Heading>{props.children}</Heading>,
                }}
                disallowedElements={['img']}>
                {text}
            </Markdown>
        </div>
    );
};

export default PostText;
