import classNames from 'classnames/bind';

import Icon, { IconSize, IconType } from './Icon';
import Spinner, { SpinnerSize } from '@/components/loading/spinner/Spinner';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    type: IconType;
    size?: 'sm' | 'base' | 'lg' | 'xl' | 'xxl';
    rounded?: boolean;
    iconSize?: IconSize;
    iconFilled?: boolean;
    ariaLabel: string;
    theme?: ElementTheme;
    busy?: boolean;
    disabled?: boolean;
    disableHover?: boolean;
    nudge?: boolean;
    transparent?: boolean;
    onPress?: () => void;
}

const IconButton: React.FC<Props> = ({
    type,
    rounded = true,
    iconSize = IconSize.Small,
    iconFilled,
    ariaLabel,
    theme = 'outline',
    busy = false,
    size = 'base',
    disabled,
    disableHover = false,
    nudge,
    transparent,
    onPress,
}) => {
    return (
        <button
            type='button'
            className={cx(
                'icon-button',
                { 'icon-button--rounded': rounded },
                `icon-button--theme-${theme}`,
                `icon-button--${size}`,
                { 'icon--filled': iconFilled },
                { 'icon--nudge': nudge },
                { 'icon--transparent': transparent },
                { 'icon--disable-hover': disableHover || busy }
            )}
            aria-label={ariaLabel}
            onClick={onPress}
            disabled={disabled}>
            {!busy ? (
                <Icon name={type} size={iconSize} />
            ) : (
                <Spinner size={SpinnerSize.Small} />
            )}
        </button>
    );
};

export default IconButton;
