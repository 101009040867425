import { ReactNode } from 'react';
import classNames from 'classnames/bind';

import A11yButton from '@/components/a11y-button/A11yButton';
import CardContent from '@/components/containers/card/CardContent';
import Spinner from '@/components/loading/spinner/Spinner';
import { IconType } from '@/components/icon/Icon';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    icon?: IconType;
    hintIcon?: IconType;
    titleComponent?: ReactNode;
    title?: string;
    message?: string;
    onPress?: () => void;
    size?: 'small' | 'default' | 'large';
    wrap?: boolean;
    theme?: ElementTheme;
    truncateText?: boolean;
    busy?: boolean;
    active?: boolean;
    fitContent?: boolean;
    children?: ReactNode;
    inlineText?: boolean;
    hasPadding?: boolean;
}

const Card: React.FC<Props> = ({
    icon,
    hintIcon,
    titleComponent,
    title,
    message,
    onPress,
    size = 'default',
    wrap = false,
    theme = 'primary',
    truncateText = false,
    busy = false,
    active = false,
    fitContent = false,
    children,
    hasPadding = true,
    inlineText = false,
}) => {
    return (
        <div
            className={cx(
                'card',
                `card--size-${size}`,
                `card--${theme}`,
                {
                    'card--wrap': wrap,
                },
                { 'card--busy': busy },
                { 'card--truncate': truncateText },
                { 'card--haschildren': children },
                { 'card--active': active },
                { 'card--fit-content': fitContent },
                { 'card--remove-padding': !hasPadding }
            )}>
            {busy ? <Spinner /> : null}
            {onPress && !busy ? (
                <A11yButton active={active}>
                    <CardContent
                        icon={icon}
                        hintIcon={hintIcon}
                        titleComponent={titleComponent}
                        title={title}
                        message={message}
                        onPress={onPress}
                        size={size}
                        inline={inlineText}>
                        {children}
                    </CardContent>
                </A11yButton>
            ) : (
                <CardContent
                    icon={icon}
                    hintIcon={hintIcon}
                    titleComponent={titleComponent}
                    title={title}
                    message={message}
                    size={size}
                    inline={inlineText}>
                    {children}
                </CardContent>
            )}
        </div>
    );
};

export default Card;
