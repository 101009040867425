import { createSlice } from '@reduxjs/toolkit';
import { SearchSources } from '@/utils/constants';

export interface SearchState {
    searchDataSource: SearchSources;
    _lastUpdated?: number | null;
}

const initialState: SearchState = {
    searchDataSource: SearchSources.Festivals,
    _lastUpdated: null,
};

const search = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setDataSource: (state, action) => {
            state.searchDataSource = action.payload;
            state._lastUpdated = Date.now();
        },
    },
});

export const selectSearch = (state) => state.search;
export const { setDataSource } = search.actions;

export default search.reducer;
