import { createSlice } from '@reduxjs/toolkit';
import _sortBy from 'lodash/sortBy';
import _orderBy from 'lodash/orderBy';

export interface UserState {
    id?: number | null;
    ipAddress?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    username?: string | null;
    city?: string | null;
    country?: string | null;
    region?: string | null;
    countryId?: number | null;
    continent?: string | null;
    currentLocation?: Record<any, unknown> | null;
    optinEmailUpdates: boolean | null;
    optinEmailFestivalRecommendations: boolean | null;
    optinEmailOffers: boolean | null;
    connectedApple: boolean;
    connectedAppleMusic: boolean;
    connectedDeezer: boolean;
    connectedFacebook: boolean;
    connectedYoutubeMusic: boolean;
    connectedSpotify: boolean;
    nopass: boolean;
    userFestivals: Festival[];
    userArtists: Artist[];
    userCrate: Festival[];
    _lastUpdated?: number | null;
}

const initialState: UserState = {
    id: null,
    ipAddress: null,
    firstName: null,
    lastName: null,
    email: null,
    username: null,
    city: null,
    country: null,
    region: null,
    countryId: null,
    continent: null,
    currentLocation: null,
    optinEmailUpdates: false,
    optinEmailFestivalRecommendations: false,
    optinEmailOffers: false,
    connectedApple: false,
    connectedAppleMusic: false,
    connectedDeezer: false,
    connectedFacebook: false,
    connectedYoutubeMusic: false,
    connectedSpotify: false,
    nopass: false,
    userFestivals: [],
    userArtists: [],
    userCrate: [],
    _lastUpdated: null,
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIpAddress: (state, { payload }) => {
            state.ipAddress = payload;
            state._lastUpdated = Date.now();
        },
        setUsername: (state, { payload }) => {
            state.username = payload;
            state._lastUpdated = Date.now();
        },
        setFirstName: (state, { payload }) => {
            state.firstName = payload;
            state._lastUpdated = Date.now();
        },
        setLastName: (state, { payload }) => {
            state.lastName = payload;
            state._lastUpdated = Date.now();
        },
        setEmail: (state, { payload }) => {
            state.email = payload;
            state._lastUpdated = Date.now();
        },
        setCity: (state, { payload }) => {
            state.city = payload;
            state._lastUpdated = Date.now();
        },
        setRegion: (state, { payload }) => {
            state.region = payload;
            state._lastUpdated = Date.now();
        },
        setCountry: (state, { payload }) => {
            state.country = payload;
            state._lastUpdated = Date.now();
        },
        setCountryId: (state, { payload }) => {
            state.countryId = payload;
            state._lastUpdated = Date.now();
        },
        setContinent: (state, { payload }) => {
            state.continent = payload;
            state._lastUpdated = Date.now();
        },
        setCurrentLocation: (state, { payload }) => {
            state.currentLocation = payload;
            state._lastUpdated = Date.now();
        },
        setUserDetails: (state, { payload }) => {
            state.id = payload?.id ?? null;
            state.firstName = payload?.firstName ?? null;
            state.lastName = payload?.lastName ?? null;
            state.email = payload?.email ?? null;
            state.username = payload?.username ?? null;
            state.optinEmailUpdates = payload?.optinEmailUpdates ?? null;
            state.optinEmailFestivalRecommendations =
                payload?.optinEmailFestivalRecommendations ?? null;
            state.optinEmailOffers = payload?.optinEmailOffers ?? null;
            state.connectedFacebook = payload?.connectedFacebook ? true : false;
            state.connectedApple = payload?.connectedApple ? true : false;
            state.connectedDeezer = payload?.connectedDeezer ? true : false;
            state.connectedSpotify = payload?.connectedSpotify ? true : false;
            state.nopass = payload?.nopass;
            state._lastUpdated = Date.now();
        },
        setNotificationPref: (state, { payload }) => {
            if (payload?.optinEmailUpdates !== undefined)
                state.optinEmailUpdates = payload?.optinEmailUpdates;

            if (payload?.optinEmailFestivalRecommendations !== undefined)
                state.optinEmailFestivalRecommendations =
                    payload?.optinEmailFestivalRecommendations;

            if (payload?.optinEmailOffers !== undefined)
                state.optinEmailOffers = payload?.optinEmailOffers;

            state._lastUpdated = Date.now();
        },
        connectApple: (state, { payload }) => {
            state.connectedApple = payload;
            state._lastUpdated = Date.now();
        },
        connectAppleMusic: (state, { payload }) => {
            state.connectedAppleMusic = payload;
            state._lastUpdated = Date.now();
        },
        connectSpotify: (state, { payload }) => {
            state.connectedSpotify = payload;
            state._lastUpdated = Date.now();
        },
        connectYoutubeMusic: (state, { payload }) => {
            state.connectedYoutubeMusic = payload;
            state._lastUpdated = Date.now();
        },
        connectDeezer: (state, { payload }) => {
            state.connectedDeezer = payload;
            state._lastUpdated = Date.now();
        },
        connectFacebook: (state, { payload }) => {
            state.connectedFacebook = payload;
            state._lastUpdated = Date.now();
        },
        addUserFestival: (state, { payload }) => {
            const festivals = [...state.userFestivals, payload];
            state.userFestivals = _sortBy(festivals, ['upcomingDate']);
            state._lastUpdated = Date.now();
        },
        setUserFestivals: (state, { payload }) => {
            state.userFestivals = payload;
            state._lastUpdated = Date.now();
        },
        addUserArtist: (state, { payload }) => {
            const artists = [...state.userArtists, payload];
            state.userArtists = _sortBy(artists, ['source', 'name']);
            state._lastUpdated = Date.now();
        },
        setUserArtists: (state, { payload }) => {
            state.userArtists = payload;
        },
        addCrateFestival: (state, { payload }) => {
            const festivals = [...state.userCrate, payload];
            state.userCrate = _orderBy(
                festivals,
                ['favoriteArtistCount'],
                ['desc']
            );
            state._lastUpdated = Date.now();
        },
        updateCrateFestival: (state, { payload }) => {
            const { festival, changes } = payload;
            const fIdx =
                state.userCrate?.findIndex((f) => f.id === festival.id) ?? -1;

            if (fIdx > -1) state.userCrate[fIdx] = { ...festival, ...changes };

            state._lastUpdated = Date.now();
        },
        setCrateFestivals: (state, { payload }) => {
            state.userCrate = payload;
            state._lastUpdated = Date.now();
        },
    },
});

export const selectUser = (state) => state.user;
export const {
    setIpAddress,
    setUsername,
    setFirstName,
    setLastName,
    setEmail,
    setCity,
    setCountry,
    setCountryId,
    setCurrentLocation,
    setRegion,
    setContinent,
    setUserDetails,
    setNotificationPref,
    connectApple,
    connectAppleMusic,
    connectSpotify,
    connectYoutubeMusic,
    connectDeezer,
    connectFacebook,
    addUserFestival,
    setUserFestivals,
    addUserArtist,
    setUserArtists,
    addCrateFestival,
    updateCrateFestival,
    setCrateFestivals,
} = user.actions;

export default user.reducer;
