import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';

const slugifyConfig = {
    replacement: '-',
    lower: true,
    strict: true,
    trim: true,
};

export const parseNameFromString = (str?: string) => {
    let firstName;
    let lastName;

    if (str) {
        const name = str.split(' ');

        firstName = name?.[0];
        name?.shift();

        lastName = name?.join(' ');
    }

    return {
        firstName,
        lastName,
    };
};

export const genUid = () => uuidv4();

export const makeSlug = (str: string) => slugify(str, slugifyConfig);

export interface IAnimate {
    start: (
        duration: number,
        callbackFn: (elapsedTime: number) => void
    ) => void;
    stop: () => void;
}

export const animate = (): IAnimate => {
    let reqId: number | undefined;
    let start: number | undefined;
    let previousTime: number | undefined;
    let done = false;
    let maxDuration: number | undefined;
    let fn: (elapsedTime: number) => void;

    const step = (currentTime: number) => {
        if (done) return;

        if (start === undefined) start = currentTime;

        const elapsed = currentTime - start;

        if (previousTime !== currentTime) fn?.(elapsed);

        if (maxDuration && elapsed < maxDuration) {
            previousTime = currentTime;

            if (!done) {
                reqId = window.requestAnimationFrame(step);
            }
        }
    };

    const reset = () => {
        reqId = undefined;
        start = undefined;
        previousTime = undefined;
        done = false;
        maxDuration = undefined;
        fn = () => {};
    };

    return {
        start: (
            duration: number,
            callbackFn: (elapsedTime: number) => void
        ) => {
            fn = callbackFn;
            maxDuration = duration;
            reqId = window.requestAnimationFrame(step);
        },
        stop: () => {
            done = true;
            if (reqId) window.cancelAnimationFrame(reqId);
            reset();
        },
    };
};

export const storageKey = 'festgps.WebCache_';

export const browserCache = {
    getAll() {
        if (typeof window !== 'undefined') {
            const data = window.localStorage.getItem(storageKey);

            if (data) return JSON.parse(data);

            return null;
        }

        return null;
    },
    get(key) {
        const data = browserCache.getAll();
        if (data) return data?.[key] ?? null;

        return null;
    },
    set(key, val) {
        if (typeof window !== 'undefined') {
            const data = browserCache.getAll() || {};

            if (data) {
                data[key] = val;
                window.localStorage.setItem(storageKey, JSON.stringify(data));
            }
        }

        return null;
    },
    remove(key) {
        if (typeof window !== 'undefined') {
            const data = browserCache.getAll();

            if (data) {
                if (key in data) {
                    delete data[key];
                    window.localStorage.setItem(
                        storageKey,
                        JSON.stringify(data)
                    );
                }
            }
        }

        return null;
    },
};

export const copyObject = (obj: Record<string, unknown>) => {
    return JSON.parse(JSON.stringify(obj));
};

export const isMobile = () => {
    if (typeof window !== 'undefined') {
        return window.matchMedia('only screen and (max-width: 640px)').matches;
    }

    return false;
};
export const isTablet = () => {
    if (typeof window !== 'undefined') {
        return window.matchMedia(
            'only screen and (min-wdith:640px) and (max-width: 1024px)'
        ).matches;
    }

    return false;
};

interface NewWindowProps {
    url: string;
    w: number;
    h: number;
    title?: string;
}

export const newWindow = ({ url, title = '', w, h }: NewWindowProps) => {
    const xPos = Math.round((screen.width - w) * 0.5),
        yPos = Math.round((screen.height - h) * 0.5);
    const settings = `resizable=yes, left=${xPos}, top=${yPos}, width=${w}, height=${h}`;

    window.open(url, title, settings);
};

export const newTab = (url?: string) => {
    window.open(url, '_blank');
};

export const blockBody = (bool) => {
    if (typeof document !== 'undefined') {
        const bodyEl = document.querySelector('body');
        const headerEl = document.getElementById('app-header');

        if (bool) {
            if (bodyEl) {
                bodyEl.style.top = `-${window.scrollY}px`;
                bodyEl.style.overflow = 'hidden';
            }

            if (headerEl) headerEl.style.zIndex = '30';
        } else {
            if (bodyEl) {
                const scrollY = bodyEl.style.top;
                bodyEl.style.top = '';
                bodyEl.style.overflow = '';
                bodyEl.style.paddingRight = '';

                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            }

            if (headerEl) headerEl.style.zIndex = '';
        }
    }
};

export const isWebview = () => {
    if (typeof window !== 'undefined') {
        const ua = window.navigator.userAgent;
        const rules = [
            'WebView',
            '(iPhone|iPod|iPad)(?!.*Safari)',
            'Android.*(wv|.0.0.0)',
            'Linux; U; Android',
        ];

        const regExp = new RegExp('(' + rules.join('|') + ')', 'ig');
        return !!ua.match(regExp);
    }

    return false;
};

export const bounds = (elem) => {
    const rect = elem.getBoundingClientRect();

    return {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
    };
};

export const outerWidth = (elem) => {
    let width = elem.offsetWidth;
    const style = getComputedStyle(elem);

    width += parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
};

export const outerHeight = (elem) => {
    let height = elem.offsetHeight;
    const style = getComputedStyle(elem);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
};

export const getWinSize = () => {
    if (typeof window !== 'undefined') {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;
        const y = w.innerHeight || e.clientHeight || g.clientHeight;

        return { width: x, height: y };
    }

    return { width: 0, height: 0 };
};

export const inViewport = (elem, entireBox = false) => {
    if (typeof window !== 'undefined') {
        const bds = bounds(elem);
        const doc = getWinSize();

        if (!entireBox) {
            return (
                bds.top >= 0 &&
                bds.left >= 0 &&
                bds.top <= doc.height &&
                bds.left <= doc.width
            );
        } else {
            const topLimit = bds.top - doc.height;
            const bottomLimit = bds.top + bds.height;
            const leftLimit = bds.left - doc.width;
            const rightLimit = bds.left + bds.width;

            if (
                topLimit <= 0 &&
                bottomLimit >= 0 &&
                leftLimit <= 0 &&
                rightLimit >= 0
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    return false;
};

export const debounce = (func, wait = 18, immediate) => {
    let timeout;

    return function (...args) {
        const later = () => {
            timeout = undefined;

            if (!immediate) {
                func.apply(this, args);
            }
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(this, args);
        }
    };
};

export const stripTags = (str) => {
    return str.replace(/(<([^>]+)>)/gi, '');
};

export const removeLineBreaks = (str) => {
    return str.replace(/(\r\n|\n|\r)/gm, ' ');
};

export const removeLinks = (str) => {
    return str.replace(/http\S+/g, '');
};

export const genStr = (len: number) => {
    const chars =
        '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (var i = len; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
};

// TODO: ensure valid is checked for all forms
export const validateForm = (form, schema) => {
    const formInputs = form.elements;
    const formData = new FormData(form);
    const schemaCopy = copyObject(schema);
    const jsonData = {};
    let noErrors = true;

    for (let e = 0; e < formInputs.length; e++) {
        const elem = formInputs[e];
        const type = elem.type;
        const id = elem.id;

        if (elem.hasAttribute('required')) {
            const valid =
                type === 'select-one'
                    ? elem.value
                        ? true
                        : false
                    : elem.checkValidity();

            if (!valid) {
                if (id in schemaCopy) {
                    schemaCopy[id].value = elem.value;
                    schemaCopy[id].hasError = true;
                    schemaCopy[id].errorMessage =
                        schemaCopy[id].customErrorMessage ||
                        'This field is required';

                    noErrors = false;
                } else {
                    noErrors = false;
                }
            } else {
                if (id in schemaCopy) {
                    jsonData[id] = elem.value;
                    schemaCopy[id].value = elem.value;
                    schemaCopy[id].hasError = false;
                    schemaCopy[id].errorMessage = '';
                } else {
                    noErrors = false;
                }
            }
        } else {
            if (id in schemaCopy) {
                if (['checkbox'].includes(type)) {
                    jsonData[id] = elem.checked;
                    schemaCopy[id].value = elem.checked;
                    schemaCopy[id].hasError = false;
                    schemaCopy[id].errorMessage = '';
                } else {
                    jsonData[id] = elem.value;
                    schemaCopy[id].value = elem.value;
                    schemaCopy[id].hasError = false;
                    schemaCopy[id].errorMessage = '';
                }
            }
        }
    }

    return {
        formData,
        jsonData: jsonData,
        formSchema: schemaCopy,
        valid: noErrors,
    };
};

export const formErrorHandler = (err, schema) => {
    const { message, statusCode } = err;
    const schemaCopy = copyObject(schema);
    const totalErrors = Array.isArray(message) ? message.length : 0;

    let messageStr = totalErrors ? [] : message;

    if (totalErrors) {
        const errors = message;

        errors.forEach((item) => {
            const field = item.property;
            const message = Object.keys(item.constraints)
                .map((k) => item.constraints[k].replace(field, 'Value'))
                .join();

            if (field in schemaCopy) {
                schemaCopy[field].hasError = true;
                schemaCopy[field].errorMessage = message;
                messageStr.push(message);
            }
        });
    }

    if (statusCode === 500) {
        messageStr =
            'We are currently experiencing technical difficulties. Please contact technical support';
    }

    return {
        errorSchema: schemaCopy,
        error: {
            type: 'error',
            body: totalErrors ? messageStr.join(', ') : messageStr,
            visible: true,
        },
    };
};

export const getFormattedDate = (date?: string) => {
    if (date) return format(new Date(date), 'MMM d, yyyy');

    return;
};

export const getShortDate = (date?: string) => {
    if (date) return format(new Date(date), 'MMM d/yy');

    return;
};

export const getImage = (thumbnail?: ResultImage, size = 'small') => {
    let url = thumbnail?.formats?.[size]?.url;

    if (!url) url = thumbnail?.url;
    if (!url) url = thumbnail?.formats?.thumbnail?.url;
    if (!url && typeof thumbnail === 'string') url = thumbnail;
    if (url) return `${process.env.NEXT_PUBLIC_CDN_URL}${url}`;

    return null;
};

export const imageToDataUrl = async ({ src, mimeType }): Promise<string> => {
    const image = new Image();
    let dataUrl;

    return new Promise((resolve, reject) => {
        image.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                canvas.height = image.naturalHeight;
                canvas.width = image.naturalWidth;

                ctx.drawImage(image, 0, 0);

                dataUrl = canvas.toDataURL(mimeType);

                resolve(dataUrl);
            } else {
                reject;
            }
        };

        image.onerror = reject;
        image.src = src;
    });
};

export const isInView = async (
    container: HTMLDivElement | HTMLElement
): Promise<boolean> => {
    if ('IntersectionObserver' in window) {
        return new Promise((resolve) => {
            const observer = new IntersectionObserver((entries) => {
                const ratio = entries[0].intersectionRatio;

                if (ratio <= 0) resolve(false);
                if (container && ratio > 0.2) {
                    observer.unobserve(container);
                    resolve(true);
                }
            });

            if (container) observer.observe(container);
        });
    } else {
        return true;
    }
};

export const getPosition = (e): { x: number; y: number } => {
    let posX = 0;
    let posY = 0;

    const rect = e.getBoundingClientRect();
    posX = rect.x;
    posY = rect.y;

    return {
        x: posX,
        y: posY,
    };
};

export const findScrollContainer = (element, className: string, depth = 5) => {
    if (!element) return undefined;

    let parent = element.parentElement;
    let count = 0;

    while (parent) {
        const { overflow } = window.getComputedStyle(parent);

        if (parent?.className.includes(className)) {
            if (
                overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')
            )
                return parent;
        }

        parent = parent.parentElement;

        count++;

        if (count === depth) return;
    }

    return document.documentElement;
};
