interface Props {
    escHandler: () => void;
}

const useEsc = ({ escHandler }: Props) => {
    if (typeof document !== 'undefined') {
        const handleEscKey = (evt: KeyboardEvent) => {
            if (evt.key === 'Escape') escHandler();
        };

        document.addEventListener('keyup', handleEscKey, false);
    }
};

export default useEsc;
